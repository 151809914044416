import {useContext, useEffect} from "react";
import AuthContext from "../context/AuthContext";
import {useForm} from "react-hook-form";
import {addLngLatLocations} from "../utils/locations";
import {toast} from "react-toastify";

const useUpdateSettings = () => {

  const {user, updatePassword, updateProfile,} = useContext(AuthContext);

  const {
    control,
    getValues,
    formState: {errors ,isValid},
    handleSubmit,
    register,
    resetField,
    setError,
    setValue,
    watch
  } = useForm({
    defaultValues: user,
    mode: 'onSubmit',
    /*reValidateMode: 'onChange'*/
  });

  const {
    control: passwordControl,
    register: passwordRegister,
    handleSubmit: passwordHandleSubmit,
    setError: passwordSetError,
    formState: {errors: passwordErrors}
  } = useForm();

  const submitSettings = async (formData) => {
    try {
      const locationAppendedFormData = await addLngLatLocations(formData,user);

      // update endpoints expect the avatar images to be files rather than just the url strings
      // so if a new image has been set on the form then pull this out of the field otherwise just clear
      // the two url fields and the backend will just leave this as is
      if (formData.profile_photo && typeof formData.profile_photo === 'object') {
        formData.profile_photo = formData.profile_photo[0];
      } else {
        delete formData.profile_photo;
      }

      delete formData.profile_photo_sml;
      delete formData.profile_photo_med;
      delete formData.profile_photo_lrg;

      await toast.promise(
        updateProfile(locationAppendedFormData),
        {
          pending: 'Saving your profile',
          success: 'Your profile has been saved',
          error: 'There was a problem saving your profile'
        }
      )
    } catch (error) {
      // parse error response to extract specific field errors
      if (error.response && error.response.data) {
        for (const [key, value] of Object.entries(error.response.data)) {
          setError(key, {
            message: value
          });
        }
      } else {
        setError('non_field_errors', {
          message: 'Error trying to save settings, please try again.'
        });
      }
    }
  };

  const changePassword = async (formData) => {
    try {
      await toast.promise(
        updatePassword(formData.new_password, formData.re_new_password, formData.current_password),
        {
          pending: 'Saving your password',
          success: 'Your password has been saved',
          error: 'There was a problem saving your password'
        }
      )
    } catch (error) {
      // parse error response to extract specific field errors
      if (error.response && error.response.data) {
        for (const [key, value] of Object.entries(error.response.data)) {
          passwordSetError(key, {
            message: value
          });
        }
      } else {
        setError('non_field_errors', {
          message: 'Error trying to update password, please try again.'
        });
      }
    }
  };

  return {
    changePassword,
    errors,
    control,
    getValues,
    handleSubmit,
    isValid,
    passwordControl,
    passwordErrors,
    passwordHandleSubmit,
    passwordRegister,
    register,
    resetField,
    setValue,
    submitSettings,
    user,
    watch
  }

}

export default useUpdateSettings;
import axios from "axios";
import { RematchDispatch } from "@rematch/core";

export default {
  state: {
    profiles: [],
    publicProfile: undefined,
    isLoading: false,
  }, // initial state
  reducers: {
    add: (state, payload) => {
      return {
        ...state,
        profiles: payload.profiles,
      };
    },
    addProfileById: (state, payload) => {
      return {
        ...state,
        profiles: [
          ...state.profiles.filter(({ id }) => id !== payload.id),
          payload,
        ],
      };
    },

    addPublicProfileById: (state, payload) => {
      return {
        ...state,
        publicProfile: payload,
      };
    },
    isLoading: (state, payload) => {
      return {
        ...state,
        isLoading: payload,
      };
    },
  },
  effects: (dispatch) => ({
    async loadData(params) {
      this.isLoading(true);

      // if the data is more than 5 minutes old
      try {
        const response = await axios.get(`/api/v1/search/?${params.join("&")}`);

        if (response.status === 200) {
          this.add({
            profiles: response.data,
          });
        } else {
          console.error("get users error status " + response.status);
        }

        this.isLoading(false);
      } catch (e) {
        console.error(e);
        this.isLoading(false);
      }
    },

    async getProfileById(id) {
      this.isLoading(true);
      try {
        //const response = await axios.get(`/api/v1/auth/users/${id}`);
        const response = await axios.get(`/api/v1/auth/users/${id}/`);

        if (response.status === 200) {
          this.addProfileById(response.data);
        } else {
          console.error("get users by id error status " + response.status);
        }
        this.isLoading(false);
      } catch (e) {
        console.error(e);
        this.isLoading(false);
      }
    },

    async getPublicProfileById(id) {
      this.isLoading(true);
      try {
        const response = await axios.get(`/api/v1/profile/${id}`);

        if (response.status === 200) {
          this.addPublicProfileById(response.data);
        } else {
          console.error("get users by id error status " + response.status);
        }
        this.isLoading(false);
      } catch (e) {
        console.error(e);
        this.isLoading(false);
      }
    },
  }),
};

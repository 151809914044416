import axios from "axios";

export const setAxiosAuthToken = (token) => {
  if (typeof token !== "undefined" && token) {
    // Apply for every request
    axios.defaults.headers.common["Authorization"] = "Token " + token;
  } else {
    // Delete auth header
    delete axios.defaults.headers.common["Authorization"];
  }
};

export const getReactAppApiBaseUrl = function () {
  return window.Config.REACT_APP_API_BASE_URL;
};

export const getReactAppLccEmail = function () {
  return window.Config.REACT_APP_LCC_EMAIL;
};

export const getExternalFAQUrl = function () {
  //old
  //return "https://lcc.org.uk/groups/cycle-buddies/#find-buddy";
  //new
  return "https://lcc.org.uk/groups/cycle-buddies-faqs/";
};

export const limitDisplayName = (displayName, displayNameLimit) => {
  if (!displayName) return "";

  if (displayName.length > displayNameLimit) {
    return displayName.substring(0, displayNameLimit - 3) + "...";
  }

  return displayName;
};

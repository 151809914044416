import styled from 'styled-components';
import { Bars } from '~/icons';

export const DetailsIcon = styled(Bars).attrs({ width: 16, height: 16 })`
  cursor: pointer;
  fill: ${({ theme }) => theme.palette.neutral.main};
  align-self: center;
`;

export const ChatHeaderContainer = styled.div`
  height: 96px;
  padding: 10px 10px;
  background: ${({ theme }) => theme.palette.system.background};
  border-top: 1px solid #e3e4e8;
  border-bottom: 1px solid #e3e4e8;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media (min-width: 575px) {
    height: 76px;
    flex-direction: row;
    padding: 0 20px;
  }
`;

export const Channel = styled.div`
  display: flex;
  align-items: center;
  height: 74px;
`;

export const ChannelInfo = styled.div`
  margin-left: 8px;
`;

export const ChannelName = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: #000000;
`;

export const MemberCount = styled.div`
  font-size: 12px;
  color: #999999;
`;

export const ReportMember = styled.div`
  font-size: .8rem;
  padding: .8rem 0 .8rem  3rem;
  display:  flex;
  text-align: right;
  a {
    color: gray;
  }
  @media (min-width: 575px) {
    padding-left: .8rem;
    align-self: center;
  }
`;
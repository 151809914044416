import AuthContext from "../context/AuthContext";
import {useContext, useEffect, useRef, useState } from "react";

const useIsLoggedIn = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const { user, hasCalledLoginAPI } = useContext(AuthContext);
  useEffect(() => {
    if ( hasCalledLoginAPI ) {
      if (user === null) {
        setIsLoggedIn(false)
      } else if (typeof user === 'object') {
        setIsLoggedIn(true);
      }
    }
  }, [user, hasCalledLoginAPI]);

  return { hasCalledLoginAPI, user, isLoggedIn } ;
}

export default useIsLoggedIn;
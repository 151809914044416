import {useState, useContext} from "react";
import {Button, Form} from "react-bootstrap";
import AuthContext from "../context/AuthContext";
import {Controller, useForm} from "react-hook-form";
import {Link, useParams} from "react-router-dom";
import AuthenticationScreen from "../components/furniture/AuthenticationScreen";

const ResetPasswordConfirmPage = () => {

  const {confirmResetPassword} = useContext(AuthContext);
  const {uid, token} = useParams();
  const [status, setStatus] = useState(null);
  const {control, register, handleSubmit, setError, formState: {errors}} = useForm();

  const submitReset = async (formData) => {
    try {
      await confirmResetPassword(uid, token, formData.new_password, formData.re_new_password);
      setStatus('success');
    } catch (error) {
      setStatus('error');

      // parse error response to extract specific field errors
      if (error.response && error.response.data) {
        for (const [key, value] of Object.entries(error.response.data)) {
          setError(key, {
            message: value
          });
        }
      } else {
        setError('non_field_errors', {
          message: 'Error trying to reset password, please try again.'
        })
      }
    }
  };

  return (
    <AuthenticationScreen title="Reset Password">


      {status === 'success' && (
        <>
          <p>
          Password Reset. You can now log in using your new credentials.
          </p>

          <Link
            className="btn btn-primary action-button rounded-pill px-3"
            to="/login">Log in</Link>

        </>
      )}

      {status !== 'success' && (
        <>
          <form onSubmit={handleSubmit(submitReset)} noValidate={true}>
            <input
              {...register("non_field_errors")}
              type="hidden" />


            {status === 'error' && (
              <p className="text-danger d-block">
                Please correct below.
              </p>
            )}

            <Form.Group className="form-group">
              <Form.Label>New password</Form.Label>
              <Controller
                name="new_password"
                control={control}
                render={({field}) => <Form.Control
                {...field}
                  type="password"
                  id="new_password"
                  name="new_password"
                  placeholder="New password"
                  required
                  />}
                />
              {!!errors?.new_password?.message && <p className="invalid-feedback d-block">{errors?.new_password?.message}</p>}
            </Form.Group>

            <Form.Group className="form-group">
              <Form.Label>Confirm new password</Form.Label>
              <Controller
                name="re_new_password"
                control={control}
                render={({field}) => <Form.Control
                  {...field}
                  type="password"
                  id="re_new_password"
                  name="re_new_password"
                  placeholder="Re-type new password"
                  required
                />}
                />
              {!!errors?.re_new_password?.message && <p className="invalid-feedback d-block">{errors?.re_new_password?.message}</p>}
            </Form.Group>

            <Button
              type="submit"
              variant="primary"
              name="update"
              className="action-button rounded-pill px-3"
              value="Update">Save</Button>
            </form>
        </>
      )}
   </AuthenticationScreen>
  )
}

export default ResetPasswordConfirmPage;
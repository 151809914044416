import React from 'react';
import {Card} from "react-bootstrap";
import PropTypes from "prop-types";

const SettingsTab = ({title,children}) => {

  return <Card>
      { !!title && <Card.Header className="d-flex justify-content-between px-3 py-3 px-md-5 py-md-4 rounded-0">
        <div className="header-title">
          <h2 className="h2 card-title">{title}</h2>
        </div>
      </Card.Header>}
      <Card.Body className="px-3 py-3 px-md-5 py-md-4">
        {children}
      </Card.Body>
  </Card>

}

SettingsTab.defaultProps = {
  title : ''
}

SettingsTab.propTypes = {
  title : PropTypes.string
}

export default SettingsTab;
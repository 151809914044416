import { Navigate } from "react-router-dom";
import { useParams } from "react-router-dom";

import Layout from "../components/Layout";
import useIsLoggedIn from "../hooks/useIsLoggedIn";
import PublicProfilePage from "../pages/PublicProfilePage";
import ProfilePage from "../pages/ProfilePage";

const ProfileRoute = ({ children }) => {
  const urlParams = useParams();
  const { hasCalledLoginAPI, user, isLoggedIn } = useIsLoggedIn();

  if (!hasCalledLoginAPI || isLoggedIn === null) {
    return <></>;
  }

  if (isLoggedIn && urlParams?.uid !== user.uid) {
    if (user?.onboarded) {
      return (
        <Layout>
          <ProfilePage />
        </Layout>
      );
    } else {
      return <Navigate to="/onboarding" />;
    }
  }

  return (
    <Layout>
      <PublicProfilePage />
    </Layout>
  );
};

export default ProfileRoute;

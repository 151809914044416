// Note: ansers are set as arrays, but not subquestions


export const isOtherFieldSetting = (fieldName) => {

	if (fieldName.includes('OTHER')) {
		return true
	} else if ( fieldName === 'PREFER_TO_SELF_DESCRIBE') {
		return true
	} else {
		return false
	}
}

const diversityQuestions = [
	{
		questionLabel : 'Ethnic Group',
		name : 'ethnic_group',
		fieldNameOther: 'other_ethnic_group',
		type : 'radio',
		required: true,   
		answers : [
			{
				label : 'White',
				name : 'WHITE',
				required: true,
				question :
					{
						questionLabel : 'Ethnic Group - White',
						showQuestion : false,
						name : 'ethnic_group_white',
						fieldNameOther : 'other_ethnic_group_white',
						type: 'radio',
						answers: [
							{
								label: 'English, Welsh, Scottish, Northern Irish or British',
								name : 'ENGLISH_WELSH_SCOTTISH_IRISH'
							},
							{
								label : 'Irish',
								name : 'IRISH'
							},
							{
								label : 'Gypsy or Irish Traveller',
								name : 'GYPSY'
							},
							{
								label : 'Roma',
								name : 'ROMA'
							},
							{
								label : 'Any other White Background',
								name : 'OTHER'
							}
						]		
					}
			},
			{
				label : 'Mixed or Multiple Ethnic Groups',
				name : 'MIXED_MULTIPLE',

				type : 'radio',   
				question : 
				{
					questionLabel : 'Ethnic Group - Mixed',
					name : 'ethnic_group_mixed_multiple',
					fieldNameOther: 'other_ethnic_group_mixed_multiple',
					showQuestion : false,
					type: 'radio',
					answers: [
						{
							label: 'White and Black Caribbean',
							name : 'WHITE_BLACK_CARIBBEAN'
						},
						{
							label : 'White and Black African',
							name : 'WHITE_BLACK_AFRICAN',
						},
						{
							label : 'White and Asian',
							name : 'WHITE_ASIAN',
						},
						{
							label : 'Any other Mixed or Multiple background',
							name : 'OTHER'
						}
					]		
				}
			},
			{
				label : 'Asian or Asian British',
				name : 'ASIAN',
				type : 'radio',    
				question : 
				{
					questionLabel : 'Ethnic Group - Asian or Asian British',
					name : 'ethnic_group_asian',
					fieldNameOther: 'other_ethnic_group_asian',
					showQuestion : false,
					type: 'radio',
					answers: [
					{
						label: 'Indian',
						name : 'INDIAN'
					},
					{
						label : 'Pakistani',
						name : 'PAKISTANI',
					},
					{
						label : 'Bangladeshi',
						name : 'BANGLADESHI',
					},
					{
						label : 'Chinese',
						name : 'CHINESE',
					},
					{
						label : 'Any other Asian background',
						name : 'OTHER'
					}
				]		
			}
		},
			{
				label : 'Black, Black British, Caribbean or African',
				name : 'BLACK_CARIBBEAN_AFRICAN',
				type : 'radio',    
				question: {
					questionLabel : 'Ethnic Group - Black, Black British, Caribbean or African',
					name : 'ethnic_group_black_carribean_african',
					fieldNameOther: 'other_ethnic_group_black_carribean_african',
					showQuestion : false,
					type: 'radio',
					answers: [
					{
						label: 'Caribbean',
						name : 'CARIBBEAN'
					},
					{
						label : 'African Background',
						name : 'AFRICAN',
					},
					{
						label : 'Any other Black, Black British or Caribbean background',
						name : 'OTHER',
					}
				]		
			}
		},
			{
				label : 'Other ',
				name : 'OTHER',

				type : 'radio',
				answers: [
					{
						label: 'Arab',
						name : 'arab'
					},
					{
						label : 'Any other ethnic group',
						name : 'other',
					}
				]
			},
            {
				label : 'Prefer not to say',
				name : 'PREFER_NOT_TO_SAY'
			}
		]
	},
	{
		questionLabel: 'Do you consider yourself to have a disability?',
		name : 'disability',
		type : 'radio',
		answers : [
			{
				label : 'Yes',
				name : 'YES',
				type : 'radio',    
				question: {
						questionLabel : 'Do you have any adjustment requirements or access needs?',
						showQuestion : true,
						name : 'requirements_access_needed',
						type : 'radio',
						answers: [
							{
								label: 'Yes',
								name : 'YES',
								type: 'radio',
								question : {
									questionLabel : 'What access needs or adjustment requirements do you have?',
									name : 'requirements_access_needs',
									showQuestion : true,
									type: 'checkbox',
										answers: [
												{
														label : 'Visual',
														name: 'VISUAL'
												},
												{
														label : 'Hearing',
														name: 'HEARING'
												},
												{
														label : 'Communication',
														name: 'COMMUNICATION'
												},
												{
														label : 'Mobility',
														name: 'MOBILITY'
												},
												{
														label : 'Learning support',
														name: 'LEARNING_SUPPORT'
												},
												{
														label : 'Mental health',
														name: 'MENTAL_HEALTH'
												},
												{
														label : 'Prefer not to say',
														name: 'PREFER_NOT_TO_SAY'
												},
												{
													label : 'If you have additional needs not listed above or any comments please list them here',
													name : 'OTHER'
												}
											]
										}
									},
							{
								label : 'No',
								name : 'NO'
							},
                        ]
                    }
                
            },
            {
                label : 'No',
                name : 'NO'
            },
            {
                label : 'Prefer not to say',
								name : 'PREFER_NOT_TO_SAY'
            }
        ]
    },
	{
		questionLabel : 'Gender',
		name : 'gender',
		fieldNameOther: 'gender_other',
		type : 'radio',
		answers : [
			{
				label : 'Male',
				name : 'MALE'
			},
			{
				label : 'Female',
				name : 'FEMALE'
			},
			{
				label : 'Prefer not to say',
				name : 'PREFER_NOT_TO_SAY'
			},
			{
				label : 'Prefer to self-describe',
				name : 'PREFER_TO_SELF_DESCRIBE',
			}
		]
	},
	{
		questionLabel : 'Sexual Orientation',
		name : 'sexual_orientation',
		type : 'radio',
		fieldNameOther: 'sexual_orientation_other',
		answers : [
			{
				label : 'Straight or Heterosexual',
				name : 'STRAIGHT_HETROSEXUAL'
			},
			{
				label : 'Gay or Lesbian',
				name : 'GAY_LESBIAN'
			},
			{
				label : 'Bisexual',
				name : 'BISEXUAL'
			},
			{
				label : 'Prefer not to say',
				name : 'PREFER_NOT_TO_SAY'
			},
			{
				label : 'Other',
				name : 'OTHER_SEXUAL_ORIENTATION',

			}
		]
	},
	{
		questionLabel : 'Spiritual beliefs',
		name : 'spiritual_beliefs',
		type : 'radio',
		fieldNameOther: 'spiritual_beliefs_other',
		answers : [
			{
				label : 'Christian',
				name : 'CHRISTIAN'
			},
			{
				label : 'Muslim',
				name : 'MUSLIM'
			},
			{
				label : 'Jewish',
				name : 'JEWISH'
			},
			{
				label : 'Buddhist',
				name : 'BUDDIST'
			},
			{
				label : 'Hindu',
				name : 'HINDU'
			},
			{
				label : 'Atheist / Agnostic',
				name : 'ATHIEST_AGNOSTIC'
			},
			{
				label : 'Other',
				name : 'OTHER'
			},
			{
				label : 'Prefer not to say',
				name : 'PREFER_NOT_TO_SAY'
			}
		]
	}
]

export default diversityQuestions;
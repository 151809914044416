import React, { useEffect, useState } from "react";
import { Col, InputGroup, Row, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import OnboardingForm from "./OnboardingForm";
import { postCodeRegex } from "../../utils/locations";

const OnboardingLocations = ({
  getValues,
  handleSubmit,
  register,
  riderType,
  handleChange,
  setValue,
  state,
  submitSettings,
  user,
  watch,
}) => {
  let navigate = useNavigate();
  const watchFields = watch(["home_postcode", "work_postcode"]);
  const [okToProceed, setOkToProceed] = useState(false);
  useEffect(() => {
    const homePostCode = getValues("home_postcode");
    const workPostCode = getValues("work_postcode");

    // No postcodes are set
    if (homePostCode === "" && workPostCode === "") {
      setOkToProceed(false);
      return;
    }

    const homePostCodeValid = !homePostCode || postCodeRegex.test(homePostCode);
    const workPostCodeValid = !workPostCode || postCodeRegex.test(workPostCode);

    // Postcodes aren't valid
    if (!homePostCodeValid || !workPostCodeValid) {
      setOkToProceed(false);
      return;
    }

    setOkToProceed(true);
  }, [watchFields]);

  return (
    <OnboardingForm
      page={3}
      previousButton={{
        label: "Previous",
        onClick: () => navigate("/onboarding/goals"),
      }}
      nextButton={{
        label: "Save and proceed",
        onClick: () => {
          let handleErrors = (e) => {
            console.error(e);
          };
          handleSubmit(submitSettings, handleErrors)();
          navigate("/onboarding/profile");
        },
        buttonEnabled: okToProceed,
      }}
      title="Locations"
    >
      {/* NEW OR RETURNING */}
      {riderType === "NEW_RETURNING" && (
        <>
          <fieldset className="form-group">
            <p>
              <b>I would like help near:</b>
            </p>
            <Row className="pb-3">
              <Col md={6}>
                <Form.Group className="mb-2">
                  <Form.Label>My home address</Form.Label>
                  <Form.Control
                    {...register("home_postcode")}
                    type="text"
                    placeholder="Postcode"
                    className="w-auto"
                    autoFocus
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-2">
                  <Form.Label>My work address</Form.Label>
                  <Form.Control
                    {...register("work_postcode")}
                    type="text"
                    placeholder="Postcode"
                    className="w-auto"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Form.Text muted>
              Only one location required, but you can add both if you wish.
              <br /> Your location will not be shared, only your approximate
              distance to other users.
            </Form.Text>
          </fieldset>
        </>
      )}

      {/* EXPERIENCED */}
      {riderType === "EXPERIENCED" && (
        <>
          <div>
            <Row className="pb-3">
              <Col md={12}>
                <fieldset className="form-group">
                  <p>
                    <b>I would like to help people near:</b>
                  </p>
                  <Row className="pb-3">
                    <Col md={6}>
                      <Form.Group className="mb-2">
                        <Form.Label>My home address</Form.Label>
                        <Form.Control
                          {...register("home_postcode")}
                          type="text"
                          placeholder="Postcode"
                          className="w-auto"
                          autoFocus
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-2">
                        <Form.Label>My work address</Form.Label>
                        <Form.Control
                          {...register("work_postcode")}
                          type="text"
                          placeholder="Postcode"
                          className="w-auto"
                          autoFocus
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Text muted>
                    Only one location required, but you can add both if you
                    wish.
                    <br />
                    Your location will not be shared, only your approximate
                    distance to other users.
                  </Form.Text>
                </fieldset>
              </Col>
            </Row>
          </div>
        </>
      )}

      {/* INSTRUCTOR */}
      {riderType === "INSTRUCTOR" && (
        <>
          <div>
            <Row className="pb-3">
              <Col md={12}>
                <fieldset className="form-group">
                  <p>
                    <b>I would like to help people near:</b>
                  </p>

                  <Row className="pb-3">
                    <Col md={6}>
                      <Form.Group className="mb-2">
                        <Form.Label>My home address</Form.Label>
                        <InputGroup className="mb-1 flex-nowrap">
                          <Form.Control
                            {...register("home_postcode")}
                            type="text"
                            placeholder="Postcode"
                            className="w-auto"
                            autoFocus
                          />
                          <Form.Select
                            {...register("number_of_miles_home")}
                            aria-label="Distance from address"
                            align="end"
                            className="w-auto"
                            required
                          >
                            {[...Array(10)].map((e, i) => {
                              return (
                                <option value={i + 1}>
                                  + {i + 1} {i === 0 ? `mile` : `miles`}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </InputGroup>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="mb-2">
                        <Form.Label>My work address</Form.Label>
                        <InputGroup className="mb-1 flex-nowrap">
                          <Form.Control
                            {...register("work_postcode")}
                            type="text"
                            placeholder="Postcode"
                            className="w-auto"
                            autoFocus
                          />
                          <Form.Select
                            {...register("number_of_miles_work")}
                            aria-label="Distance from address"
                            align="end"
                            className="w-auto"
                            required
                          >
                            {[...Array(10)].map((e, i) => {
                              return (
                                <option value={i + 1}>
                                  + {i + 1} {i === 0 ? `mile` : `miles`}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Form.Text muted>
                    {" "}
                    Your location will not be shared, only your approximate
                    distance to other users.
                  </Form.Text>
                </fieldset>
              </Col>
            </Row>
          </div>
        </>
      )}
    </OnboardingForm>
  );
};

OnboardingLocations.defaultProps = {};

OnboardingLocations.propTypes = {
  riderType: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  state: PropTypes.object,
};

export default OnboardingLocations;

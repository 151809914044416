import React, {useEffect, useRef} from "react";
import { Button, Card, Col, Row, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import OnboardingForm from "./OnboardingForm";

const OnboardingAbility = ({
                             handleSubmit,
                             register,
                             riderType,
                             setRiderType,
                             setValue,
                             submitSettings,
                             user
}) => {
  const navigate = useNavigate();

  const effectHasRun = useRef(false);

  useEffect(() => {
    if (effectHasRun.current) {
      if (!!riderType && riderType !== user?.rider_type) {
        setValue('rider_type',riderType)
        let handleErrors = (e) => { console.error(e)}
        handleSubmit(submitSettings, handleErrors)()
        navigate('goals')
      }
    }
    return () => effectHasRun.current = true;

  },[riderType])

  return (
    <OnboardingForm page={1} title="Ability">
      <input
        {...register('rider_type')}
        type="hidden"
      />
       <div className="py-2">
          <Row
            xs={1}
            md={1}
            lg={3}
            className="gx-3 gy-3 mb-3 align-items-stretch "
          >
            <Col>
              <Card className="mb-2 shadow-0 border h-100">
                <Card.Header className="pt-3 py-md-3 border-0">
                  <span className="h2 lh-1 card-title">
                    I’m a new
                    <br />
                    or returning rider
                  </span>
                </Card.Header>
                <Card.Body className="d-flex flex-column pt-0 justify-content-between">
                  <p className="my-1 my-md-2">I want help to cycle more</p>
                  <Button
                    variant={riderType === 'NEW_RETURNING' ? 'primary' : 'outline-primary'}
                    size="lg"
                    className="w-100 stretched-link rounded-pill"
                    onClick={() => {
                      if ( riderType === 'NEW_RETURNING') {
                        navigate('goals')
                      } else {
                        setRiderType("NEW_RETURNING");
                      }
                    }}
                  >
                    Select
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="mb-2 shadow-0 border h-100">
                <Card.Header className="pt-3 py-md-3 border-0">
                  <span className="h2 lh-1 card-title">
                    I’m an <br />
                    experienced rider
                  </span>
                </Card.Header>
                <Card.Body className="d-flex flex-column pt-0 justify-content-between">
                  <p className="my-1 my-md-2">
                    I want to help others cycle more
                  </p>
                  <Button
                    variant={riderType === 'EXPERIENCED' ? 'primary' : 'outline-primary'}
                    size="lg"
                    className="w-100 stretched-link rounded-pill"
                    onClick={() => {
                      if ( riderType === 'EXPERIENCED') {
                        navigate('goals')
                      } else {
                        setRiderType("EXPERIENCED");
                      }
                    }}
                  >
                    Select
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="mb-2 shadow-0 border h-100">
                <Card.Header className="pt-3 py-md-3 border-0">
                  <span className="h2 lh-1 card-title">
                    I’m a qualified instructor
                  </span>
                </Card.Header>
                <Card.Body className="d-flex flex-column pt-0 justify-content-between">
                  <p className="my-1 my-md-2">
                    I am an NSI/1st4Sport qualified cycling instructor
                  </p>
                  <Button
                    variant={riderType === 'INSTRUCTOR' ? 'primary' : 'outline-primary'}
                    size="lg"
                    className="w-100 stretched-link rounded-pill"
                    onClick={() => {
                      if ( riderType === 'INSTRUCTOR') {
                        navigate('goals')
                      } else {
                        setRiderType("INSTRUCTOR");
                      }
                    }}
                  >
                    Select
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
    </OnboardingForm>
  );
};

OnboardingAbility.defaultProps = {};

OnboardingAbility.propTypes = {
  setRiderType: PropTypes.func.isRequired,
};

export default OnboardingAbility;

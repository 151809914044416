import React, {useContext, useEffect} from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import AuthContext from "../context/AuthContext";
import {useNavigate} from "react-router-dom";
import {getExternalFAQUrl} from "../utils/Utils";

const Welcome = () => {

  /**** I'm not sure why we need to do this - should be handled elsewhere */
  const {user} = useContext(AuthContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (user?.rider_type === 'NEW_RETURNING') {
      navigate('/find-buddies');
    }
  },[user])
  /**** */

  return (
    <Container>
      <Row>
        <Col>
          <Card>
            <Card.Header className="px-3 py-3 px-md-5 py-md-5  text-md-center">
              <h1 className="h2">
                Congratulations on creating your cycle buddies profile!
              </h1>
              <p className="lead mt-2 mb-0">
                You are now all set to be a cycle buddy. What happens next?
              </p>
            </Card.Header>
            <Card.Body className="px-3 py-3 px-md-5 py-md-4">
              <p>
                Firstly, join one of{" "}
                <a
                  href="https://us02web.zoom.us/j/89622930459?pwd=T1pXVjBTZWRUQ2lHTXFsbXNzbXdPUT09"
                  target="_blank"
                  rel="noreferrer"
                >
                  our regular webinars for cycle buddie volunteers{" "}<span className="material-symbols-outlined align-middle small">open_in_new</span>
                </a>
                . There is also a network of cycle buddies in your area
                organised by a local coordinator. You can{" "}
                <a
                  href="https://lcc.org.uk/groups/lcc-local-groups"
                  target="_blank"
                  rel="noreferrer"
                >
                   contact your local coordinator here{" "}<span className="material-symbols-outlined align-middle small">open_in_new</span>
                </a>
                .
              </p>
              <p>
                Your profile can now be found by new riders looking for help in
                your area. Once someone has found you they will be able to
                message you directly and the message will show on the left hand
                side. You will be able to reply directly to discuss and arrange
                a ride together.
              </p>
              <p>
                Before you arrange to meet up,read our personal safety guidance, tips, advice and what is
                  expected, in the{" "}
                <a
                  href={`${getExternalFAQUrl()}`}
                  target="_blank"
                  rel="noreferrer"
                >
                   FAQs{" "}<span className="material-symbols-outlined align-middle small">open_in_new</span>
                </a>
                .
              </p>
              <p>
                You can edit your profile by clicking on your name in the top
                right hand corner and selecting{" "}
                <a href="/settings">
                  <i className="material-symbols-sharp align-top">
                    manage_accounts
                  </i>{" "}
                  Settings
                </a>
                . Here you can edit your profile picture, the types of help you
                want to offer and your biography. Check out the{" "}
                <a
                  href={`${getExternalFAQUrl()}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  FAQs{" "}<span className="material-symbols-outlined align-middle small">open_in_new</span>
                </a>{" "}
                for tips on what to put in your bio.
              </p>
              <p>
                You can also edit the postcodes for where you want to offer
                help. The two postcode fields are labelled &#x2018;home&#x2019;
                and &#x2018;work&#x2019;. But you can set them to a place where
                you like to do your sessions or a place you know people are
                looking for help.
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Welcome;

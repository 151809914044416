import React from 'react';
import SettingsTab from "./SettingsTab";
import {Button, Form} from "react-bootstrap";

const SettingsPassword = ({
  onSubmit,
  control,
  register,
  errors
                                 }) => {


  return <SettingsTab title="Password">
    <Form onSubmit={onSubmit} noValidate={true}>
      <input
        {...register("non_field_errors")}
        type="hidden" />
      <Form.Group className="form-group col-sm-6">
        <Form.Label>Current password</Form.Label>
        <Form.Control
          {...register("current_password")}
          type="password"
          id="current_password"
          name="current_password"
          placeholder="Current password"
          required
        />
        <Form.Control.Feedback className="invalid">
          {errors.current_password && <>{errors.current_password.message}</>}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="form-group col-sm-6">
        <Form.Label>New password</Form.Label>
        <Form.Control
          {...register("new_password")}
          type="password"
          id="new_password"
          name="new_password"
          placeholder="New password"
          required
        />
        <Form.Control.Feedback className="invalid">
          {errors.new_password && <>{errors.new_password.message}</>}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="form-group col-sm-6">
        <Form.Label>Confirm new password</Form.Label>
        <Form.Control
          {...register("re_new_password")}
          type="password"
          id="re_new_password"
          name="re_new_password"
          placeholder="Re-type new password"
          required
        />
        <Form.Control.Feedback className="invalid">
          {errors.re_new_password && <p className="error">{errors.re_new_password.message}</p>}
        </Form.Control.Feedback>
      </Form.Group>
      {errors.non_field_errors && <p className="error">{errors.non_field_errors.message}</p>}
      <div className="mb-3 mt-5 gap-2 d-flex flex-column flex-sm-row justify-content-end">
        <Button
          type="submit"
          variant="primary"
          name="update"
          className="action-button rounded-pill px-3"
          value="Update">Update Password</Button>
      </div>

    </Form>
  </SettingsTab>


}

export default SettingsPassword;
import {createContext, useState, useEffect, useRef} from "react";
import {setAxiosAuthToken} from "../utils/Utils";
import {useNavigate} from "react-router-dom";
import axios from "axios";

const AuthContext = createContext();
export default AuthContext;

export const AuthProvider = ({children}) => {
  const navigate = useNavigate();

  /**
   * Token stored in local storage so try and set state from that
   */
  const [authToken, setAuthToken] = useState(() =>
    localStorage.getItem("authToken")
      ? localStorage.getItem("authToken")
      : null
  );

  const [user, setUser] = useState(null);
  const [userChatConfig, setUserChatConfig] = useState(null);
  const [loading, setLoading] = useState(true);

  /**
   * Given error from backend check if it was because a user was not activated or if just incorrect login
   */
  const userInactive = (error) => {
    if (error.response) {
      if (
        error.response.status === 401 &&
        error.response.hasOwnProperty("data") &&
        error.response.data.hasOwnProperty("detail") &&
        error.response.data["detail"] === "User inactive or deleted."
      ) {
        return true;
      }
    }

    return false;
  }

  /**
   * Remove all state for current user
   */
  const unsetCurrentUser = () => {
    setAuthToken(null);
    setUser(null);
    setUserChatConfig(null);
    localStorage.removeItem("authToken");
  };

  /**
   * Retrieve user details for current user, if we get a bad response back log them out and check if they
   * need to activate the account first
   */
  const getCurrentUser = async () => {
    try {
      let response = await axios.get("/api/v1/auth/users/me/");

      setUser(response.data);
      await getUserChatConfig();
      setHasCalledLoginAPI(true);
    } catch (error) {
      // if the call for user details errors then remove any state for user
      unsetCurrentUser();
      console.error('Could not get current user');

      // throw so downstream can catch
      throw error;
    }
  }

  /**
   * Retrieve chat auth for current user
   */
  const getUserChatConfig = async () => {
    try {
      let response = await axios.post("/api/v1/chats/config/");
      let userChatConfig = response.data;
      //todo do something with this token?
      //todo check it's valid?
      setUserChatConfig(userChatConfig)
    } catch (error) {
      console.error('Could not get chat config for current user');
      // throw so downstream can catch
      throw error;
    }
  }
  /**
   * Login user function that will handle the login of user and then fetching of profile
   * @param email
   * @param password
   */
  const loginUser = async (email, password) => {
    try {
      let response = await axios.post("/api/v1/auth/token/login/", {
        email,
        password
      });

      const {auth_token} = response.data;

      // on login success store token in state and local storage for next load
      setAuthToken(auth_token);
      setAxiosAuthToken(auth_token);
      localStorage.setItem("authToken", auth_token);

      // retrieve user details straight away before we return
      await getCurrentUser();
    } catch (error) {
      // not sure what has happened here so just clear all user from state
      unsetCurrentUser();
      console.error('Could not complete login:', error);

      // throw so downstream can catch
      throw error;
    }
  };

  /**
   * Log user out of site and clear down user data
   */
  const logoutUser = async () => {
    try {
      await axios.post("/api/v1/auth/token/logout/");

      unsetCurrentUser();
      navigate("/login");
    } catch (error) {
      console.error("Could not log user out:", error);
      unsetCurrentUser();
    }
  };

  /**
   * Register user with given details
   * @param userDetails Registration fields
   */
  const registerUser = async (userDetails) => {
    try {
      return await axios.post("/api/v1/auth/users/", userDetails);
    } catch (error) {
      console.error("Could not register user:", error);
      throw error;
    }
  };

  /**
   * Given an ID and token try to activate a users account
   * @param uid
   * @param token
   */
  const activateUser = async (uid, token) => {
    try {
      return await axios.post("/api/v1/auth/users/activation/", {uid, token})
    } catch (error) {
      console.error("Could not activate user:", error);
      throw error;
    }
  }

  /**
   * Given email try and resend activation email to user
   * @param email
   */
  const resendActivation = async (email) => {
    try {
      return await axios.post("/api/v1/auth/users/resend_activation/", {email})
    } catch (error) {
      console.error("Could not activate user:", error);
      throw error;
    }
  }

  /**
   * Given email try send user email to reset password
   * @param email
   */
  const resetPassword = async (email) => {
    try {
      return await axios.post("/api/v1/auth/users/reset_password/", {email})
    } catch (error) {
      console.error("Could not reset password:", error);
      throw error;
    }
  }

  /**
   * Given email and reset params try and update users password
   * @param uid
   * @param token
   * @param new_password
   * @param re_new_password
   */
  const confirmResetPassword = async (uid, token, new_password, re_new_password) => {
    try {
      return await axios.post("/api/v1/auth/users/reset_password_confirm/", {
        uid,
        token,
        new_password,
        re_new_password
      })
    } catch (error) {
      console.error("Could not reset password:", error);
      throw error;
    }
  }

  /**
   * Given new password try update in system
   * @param new_password
   * @param re_new_password
   * @param current_password
   */
  const updatePassword = async (new_password, re_new_password, current_password) => {
    try {
      return await axios.post("/api/v1/auth/users/set_password/", {
        new_password,
        re_new_password,
        current_password
      })
    } catch (error) {
      console.error("Could not update password:", error);
      throw error;
    }
  }

  /**
   * Given profile settings update user
   * @param userDetails
   */
  const updateProfile = async (userDetails) => {
    try {
      let response = await axios.put("/api/v1/auth/users/me/", userDetails, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        formSerializer: {
          indexes: null // will send arrays like journey_types=NON_ROAD,journey_types=LOCAL instead of journey_types[]=NON_ROAD,journey_types[]=LOCAL
        }
      });
      setUser(response.data);
    } catch (error) {
      console.error("Could not update user:", error);
      throw error;
    }
  }

  /**
   * Will be triggered if auth token is set, deleted, changed
   * Will also retrieve the current logged in user if it hasnt done so already
   */
  const isCallingAPI = useRef(false);
  const [hasCalledLoginAPI, setHasCalledLoginAPI] = useState(false);
  useEffect(() => {
    setAxiosAuthToken(authToken);
    // if we have a token but have not loaded user yet then retrieve details
    if (!!authToken && !user && !isCallingAPI.current) {
      isCallingAPI.current = true;
      getCurrentUser()
        .then(() => {
          setLoading(false);
          isCallingAPI.current = false;
          setHasCalledLoginAPI(true);
        })
        .catch((error) => {
          isCallingAPI.current = false;
          setHasCalledLoginAPI(true);
          if (userInactive(error)) {
            navigate('/resend-activation');
          }
        });
    } else {
      setHasCalledLoginAPI(true);
      isCallingAPI.current = false;
      setLoading(false);
    }
  }, [authToken]);

  /**
   * Return functions/state available to child components
   */
  const contextData = {
    user,
    userChatConfig,
    setUser,
    authToken,
    hasCalledLoginAPI,
    setAuthToken,
    userInactive,
    registerUser,
    loginUser,
    logoutUser,
    activateUser,
    resendActivation,
    resetPassword,
    confirmResetPassword,
    updatePassword,
    updateProfile,
  };

  return (
    <AuthContext.Provider value={contextData}>
      {loading ? null : children}
    </AuthContext.Provider>
  );
};
import { useState, useEffect } from 'react';
import axios from 'axios';

export function usePartnershipOrganisations() {
    const [partnershipOrganisations, setPartnershipOrganisations] = useState(null);
    const [error, setError] = useState(null);
    // const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.get('/api/v1/auth/partnership_organisations')
        .then(response => {
            setPartnershipOrganisations(response.data)
            // setLoading(false);
        })
        .catch(err => {
            setError(err);
            // setLoading(false);
        });
    }, []);

    return { partnershipOrganisations, error }; // loading,
}
import React from 'react';
import {Button, Col, Container, Form, Image, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import logo from "../../assets/images/lcc-logo-white.svg";
import {Controller} from "react-hook-form";
import PropTypes from "prop-types";

const AuthenticationScreen = ({children,title}) => {

  return <section className="sign-in-page">
    <Container fluid className="p-0">
      <Row className="no-gutters">
        <Col md="6" className="panel-logo text-center p-0">
          <div className="sign-in-detail text-white">
            <Link className="sign-in-logo" to="#"><Image src={logo} className="img-fluid" alt="logo"/></Link>
          </div>
        </Col>
        <Col md="6" className="panel-form bg-white p-3 p-sm-5 d-flex my-auto flex-column scroller">
          <div className="sign-in-form">
            {!!title && <h1 className="mb-2">{title}</h1>}
            { children }
          </div>
        </Col>
      </Row> 
    </Container>
  </section>

}

AuthenticationScreen.defaultProps = {
  title : ''
}

AuthenticationScreen.propTypes = {
  title : PropTypes.string
}

export default AuthenticationScreen;
import React from 'react';
import PropTypes from 'prop-types';

const FormFieldErrorMessage = ({customBlankMessage,fieldError}) => {

  if ( fieldError) {
    if (fieldError?.message?.toString().length > 0) {
      return <p className="invalid-feedback d-block">{fieldError?.message?.toString()}</p>
    } else {
      return <p className="invalid-feedback d-block">{ customBlankMessage }</p>
    }
  } else {
    return <></>
  }

}

FormFieldErrorMessage.defaultProps = {
  customBlankMessage : 'This field cannot be blank.',
  fieldError: undefined
}
FormFieldErrorMessage.propTypes = {
  customBlankMessage : PropTypes.string,
  fieldError : PropTypes.object
}

export default FormFieldErrorMessage;
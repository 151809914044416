import { Navigate, NavLink, useLocation } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import AuthContext from "../context/AuthContext";
import Layout from "../components/Layout";
import useIsLoggedIn from "../hooks/useIsLoggedIn";

const PrivateRoute = ({ children, fallback }) => {
  const location = useLocation();

  const { hasCalledLoginAPI, user, isLoggedIn } = useIsLoggedIn();

  if (!hasCalledLoginAPI || isLoggedIn === null) {
    return <></>;
  } else {
    if (isLoggedIn) {
      if (location?.pathname.includes("/onboarding")) {
        return <Layout>{children}</Layout>;
      } else {
        if (user?.onboarded) {
          return <Layout>{children}</Layout>;
        } else {
          return <Navigate to="/onboarding" />;
        }
      }
    } else {
      if (fallback) {
        return fallback();
      }

      return (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100vh",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <h1 className="pb-1">You have been logged out</h1>

          <NavLink
            to="/login"
            className="action-button rounded-pill px-3 btn btn-primary"
          >
            Please Log In
          </NavLink>
        </div>
      );
    }
  }
};

export default PrivateRoute;

import React, {useEffect, useState} from "react";
import { Col, Row, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import OnboardingForm from "./OnboardingForm";

const OnboardingGoals = ({
                           getValues,
                           handleSubmit,
                           register,
                           riderType,
                           handleChange,
                           setValue,
                           state,
                           submitSettings,
                           user,
                           watch

}) => {
  let navigate = useNavigate();

  const [okToProceed, setOkToProceed] = useState(false);
  const watchFields = watch(['registration_number']);

  useEffect(() => {
    console.log('CHECK')
    if (riderType !== 'INSTRUCTOR') {
      setOkToProceed(true)
    } else {
      const registrationNumber = getValues('registration_number');
      setOkToProceed(!!registrationNumber);
    }
  },[watchFields,riderType])

  return (
    <OnboardingForm
      page={2}
      previousButton={{
        label : 'Previous',
        onClick : () => navigate("/onboarding")
      }}
      nextButton={{
        label : 'Save and proceed',
        onClick : () => {
          let handleErrors = (e) => {
            console.error(e)
          }
          handleSubmit(submitSettings, handleErrors)()
          navigate("/onboarding/locations")
        },
        buttonEnabled : okToProceed
      }}
      title="Goals"
    >
            {/* NEW OR RETURNING */}
            { riderType === "NEW_RETURNING" && (
              <>
                <Row className="pb-3">
                  <Col md={12} lg={12}>
                    <p>The questions below are to help us define your cycling skills level and what goals you want to achieve. If you can’t ride a bike we recommend you make an appointment with an NSI qualified cycling instructor. If you can ride a bike our volunteers are able to build confidence. You can also book an NSI cycling instructor to help with this as well.</p>
                    <fieldset className="form-group">
                        <p>Select a goal:</p>
                        <Form.Check className="d-flex align-items-baseline">
                          <Form.Check.Input
                            {...register('goals')}
                            type="radio"
                            name="goals"
                            id="COMPLETE_BEGINNER"
                            value="COMPLETE_BEGINNER"
                            className="flex-shrink-0"
                            defaultChecked={user?.goals && user?.goals.includes('COMPLETE_BEGINNER')}
                          />
                          <Form.Check.Label
                            className="d-inline-block my-1 ps-2"
                            htmlFor="COMPLETE_BEGINNER">
                            I am a complete beginner and would like learn to ride
                            <small className="text-muted d-block">This will match you with instructors. Sessions with instructors are either free from your local council or can be paid privately.</small>
                          </Form.Check.Label>
                        </Form.Check>
                        <Form.Check className="d-flex align-items-baseline">
                        <Form.Check.Input
                            {...register('goals')}
                            type="radio"
                            name="goals"
                            id="QUIET_STREETS"
                            value="QUIET_STREETS"
                            className="flex-shrink-0"
                            defaultChecked={user?.goals && user?.goals.includes('QUIET_STREETS')}
                          />
                          <Form.Check.Label
                            className="d-inline-block my-1 ps-2"
                            htmlFor="QUIET_STREETS">
                            I already ride in parks but I would like to have the confidence to ride on very quiet streets in my neighbourhood
                          </Form.Check.Label>
                      </Form.Check>
                        <Form.Check className="d-flex align-items-baseline">
                          <Form.Check.Input
                              {...register('goals')}
                              type="radio"
                              name="goals"
                              id="LOCAL_ROUTES"
                              value="LOCAL_ROUTES"
                              className="flex-shrink-0"
                              defaultChecked={user?.goals && user?.goals.includes('LOCAL_ROUTES')}
                            />
                            <Form.Check.Label
                              className="d-inline-block my-2 ps-2"
                              htmlFor="LOCAL_ROUTES">
                              I can already ride but I would like to be confident riding to local shops and places of interest and learn some good local routes in my neighbourhood
                          </Form.Check.Label>
                        </Form.Check>
                        <Form.Check className="d-flex align-items-baseline">
                          <Form.Check.Input
                              {...register('goals')}
                              type="radio"
                              name="goals"
                              id="LONGER_FITNESS"
                              value="LONGER_FITNESS"
                              className="flex-shrink-0"
                              defaultChecked={user?.goals && user?.goals.includes('LONGER_FITNESS')}
                            />
                            <Form.Check.Label
                              className="d-inline-block my-2 ps-2"
                              htmlFor="LONGER_FITNESS">
                                I would like to be confident to go on longer rides out of my neighbourhood for work, travel, fitness or leisure
                          </Form.Check.Label>
                        </Form.Check>
                        <Form.Check className="d-flex align-items-baseline">
                          <Form.Check.Input
                              {...register('goals')}
                              type="radio"
                              name="goals"
                              id="PARTICULAR_ROUTE"
                              value="PARTICULAR_ROUTE"
                              className="flex-shrink-0"
                              defaultChecked={user?.goals && user?.goals.includes('PARTICULAR_ROUTE')}
                            />
                            <Form.Check.Label
                              className="d-inline-block my-2 ps-2"
                              htmlFor="PARTICULAR_ROUTE">
                                I ride regularly but would like help planning and riding a particular route

                                <small className="text-muted d-block">This will match you with volunteers and instructors.</small>
                          </Form.Check.Label>
                        </Form.Check>
                        <Form.Check className="d-flex align-items-baseline">
                          <Form.Check.Input
                              {...register('goals')}
                              type="radio"
                              name="goals"
                              id="LEVEL_2_OR_3"
                              value="LEVEL_2_OR_3"
                              className="flex-shrink-0"
                              defaultChecked={user?.goals && user?.goals.includes('LEVEL_2_OR_3')}
                            />
                            <Form.Check.Label
                              className="d-inline-block my-2 ps-2"
                              htmlFor="LEVEL_2_OR_3">
                                I would like to learn{" "}<a
                                  href="https://www.bikeability.org.uk/get-cycling/cycle-training-for-children/bikeability-level-2/"
                                  className="text-nowrap"
                                  target="_blank"
                                  rel="noreferrer"
                                  >Bikeability Level 2 {" "}<span className="material-symbols-outlined align-middle small">open_in_new</span></a>{" "}and{" "}<a
                                  href="https://www.bikeability.org.uk/get-cycling/cycle-training-for-children/bikeability-level-2/"
                                  target="_blank"
                                  rel="noreferrer"
                                  className="text-nowrap"
                                  >Level 3{" "}<span className="material-symbols-outlined align-middle small">open_in_new</span></a>
                              <small className="text-muted d-block">This will match you with instructors. Sessions with instructors are either free from your local council or can be paid privately.</small>
                          </Form.Check.Label>
                        </Form.Check>
                    </fieldset>

                    </Col>
                </Row>
                <Row className="pb-3">
                  <Col md={12}>
                    <fieldset className="form-group">
                      <p>My main motivation is:</p>

                        <Form.Check className="d-flex align-items-baseline">
                          <Form.Check.Input
                            {...register('beginner_motivation')}
                            type="radio"
                            name="beginner_motivation"
                            id="ENVIRONMENT"
                            value="ENVIRONMENT"
                            className="flex-shrink-0"
                            defaultChecked={user?.beginner_motivation && user?.beginner_motivation === 'ENVIRONMENT'}
                          />
                          <Form.Check.Label
                            className="d-inline-block my-1 ps-2"
                            htmlFor="ENVIRONMENT">
                            the environment
                          </Form.Check.Label>
                        </Form.Check>

                        <Form.Check className="d-flex align-items-baseline">
                          <Form.Check.Input
                            {...register('beginner_motivation')}
                            type="radio"
                            name="beginner_motivation"
                            id="HEALTH"
                            value="HEALTH"
                            className="flex-shrink-0"
                            defaultChecked={user?.beginner_motivation && user?.beginner_motivation === 'HEALTH'}
                          />
                          <Form.Check.Label
                            className="d-inline-block my-1 ps-2"
                            htmlFor="HEALTH">
                            my health
                          </Form.Check.Label>
                        </Form.Check>

                        <Form.Check className="d-flex align-items-baseline">
                          <Form.Check.Input
                            {...register('beginner_motivation')}
                            type="radio"
                            name="beginner_motivation"
                            id="SAVING_TIME"
                            value="SAVING_TIME"
                            className="flex-shrink-0"
                            defaultChecked={user?.beginner_motivation && user?.beginner_motivation === 'SAVING_TIME'}
                          />
                          <Form.Check.Label
                            className="d-inline-block my-1 ps-2"
                            htmlFor="SAVING_TIME">
                            convenience/saving time
                          </Form.Check.Label>
                        </Form.Check>

                        <Form.Check className="d-flex align-items-baseline">
                          <Form.Check.Input
                            {...register('beginner_motivation')}
                            type="radio"
                            name="beginner_motivation"
                            id="SAVING_MONEY"
                            value="SAVING_MONEY"
                            className="flex-shrink-0"
                            defaultChecked={user?.beginner_motivation && user?.beginner_motivation === 'SAVING_MONEY'}
                          />
                          <Form.Check.Label
                            className="d-inline-block my-1 ps-2"
                            htmlFor="SAVING_MONEY">
                            saving money
                          </Form.Check.Label>
                        </Form.Check>

                        <Form.Check className="d-flex align-items-baseline">
                          <Form.Check.Input
                            {...register('beginner_motivation')}
                            type="radio"
                            name="beginner_motivation"
                            id="LEARN_NEW_SKILL"
                            value="LEARN_NEW_SKILL"
                            className="flex-shrink-0"
                            defaultChecked={user?.beginner_motivation && user?.beginner_motivation === 'LEARN_NEW_SKILL'}
                          />
                          <Form.Check.Label
                            className="d-inline-block my-1 ps-2"
                            htmlFor="LEARN_NEW_SKILL">
                            to learn a new skill
                          </Form.Check.Label>
                        </Form.Check>

                        <Form.Check className="d-flex align-items-baseline">
                          <Form.Check.Input
                            {...register('beginner_motivation')}
                            type="radio"
                            name="beginner_motivation"
                            id="FUN_LEISURE"
                            value="FUN_LEISURE"
                            className="flex-shrink-0"
                            defaultChecked={user?.beginner_motivation && user?.beginner_motivation === 'FUN_LEISURE'}
                          />
                          <Form.Check.Label
                            className="d-inline-block my-1 ps-2"
                            htmlFor="FUN_LEISURE">
                            for fun/leisure
                          </Form.Check.Label>
                        </Form.Check>

                    </fieldset>
                  </Col>
                </Row>
              </>
            )}

            {/* EXPERIENCED */}
            {riderType === "EXPERIENCED" && (
              <>
                <div>
                  <Row>
                    <Col md={12}>
                    <fieldset className="form-group">
                      <p>Please let us know the types of journey you want to help
                        new riders with (select as many as you like):</p>
                      <Form.Check className="d-flex align-items-baseline">
                      <input
                        {...register('goals')}
                        className="form-check-input"
                        type="checkbox"
                        id="QUIET_STREETS"
                        value='QUIET_STREETS'
                      />
                      <Form.Check.Label
                        className="d-inline-block my-2 ps-2"
                        htmlFor="QUIET_STREETS"
                      >
                        to have the confidence to ride on very quiet streets in the local neighbourhood
                      </Form.Check.Label>
                    </Form.Check>

                    <Form.Check className="d-flex align-items-baseline">
                      <Form.Check.Input
                        {...register('goals')}
                        type="checkbox"
                        id="LOCAL_ROUTES"
                        value="LOCAL_ROUTES"
                        required
                      />
                      <Form.Check.Label
                        className="d-inline-block my-2 ps-2"
                        htmlFor="LOCAL_ROUTES"
                      >
                        to be confident riding to local shops and places of interest and learn some good local routes
                      </Form.Check.Label>
                    </Form.Check>

                    <Form.Check className="d-flex align-items-baseline">
                      <Form.Check.Input
                        {...register('goals')}
                        type="checkbox"
                        id="LONGER_FITNESS"
                        value="LONGER_FITNESS"
                        required
                      />
                      <Form.Check.Label
                        className="d-inline-block my-2 ps-2"
                        htmlFor="LONGER_FITNESS"
                      >
                        be confident to go on longer rides out of the neighbourhood
                      </Form.Check.Label>
                    </Form.Check>

                    <Form.Check className="d-flex align-items-baseline">
                      <Form.Check.Input
                        {...register('goals')}
                        type="checkbox"
                        id="PARTICULAR_ROUTE"
                        value="PARTICULAR_ROUTE"
                        required
                      />
                      <Form.Check.Label
                        className="d-inline-block my-2 ps-2"
                        htmlFor="PARTICULAR_ROUTE"
                      >
                        planning and riding a particular route
                      </Form.Check.Label>
                    </Form.Check>
                    </fieldset>
                    </Col>
                  </Row>
                  <Row className="pb-3">
                    <Col md={12}>
                    <Form.Group className="mb-4">
                      <Form.Label>My main motivation is:</Form.Label>
                        <Form.Control
                          {...register('experienced_motivation')}
                          className="mb-1"
                          as="textarea"
                          rows={3}
                        />
                    </Form.Group>
                    </Col>
                  </Row>
                </div>
              </>
            )}

            {/* INSTRUCTOR */}
            {riderType === "INSTRUCTOR" && (
              <>
                <div>
                  <Row>
                    <Col md={12}>
                      <fieldset>
                        <p>
                        Please let us know the types of journey you want to help new riders with (select as many as you like):
                        </p>
                        <Form.Group className="form-group">
                          <Form.Check
                            className="d-flex align-items-baseline"
                            id="type-1"
                          >
                            <Form.Check.Input
                              {...register('goals')}
                              id="COMPLETE_BEGINNER"
                              value="COMPLETE_BEGINNER"
                              type="checkbox"
                            />
                            <Form.Check.Label
                              className="d-inline-block my-2 ps-2"
                              htmlFor="COMPLETE_BEGINNER"
                            >
                              Complete beginners
                            </Form.Check.Label>
                          </Form.Check>
                          <Form.Check
                            className="d-flex align-items-baseline"
                            id="type-2"
                          >
                            <Form.Check.Input
                              {...register('goals')}
                              id="PARTICULAR_ROUTE"
                              value="PARTICULAR_ROUTE"
                              type="checkbox"
                            />
                            <Form.Check.Label
                              className="d-inline-block my-2 ps-2"
                              htmlFor="PARTICULAR_ROUTE"
                            >
                              Planning and riding a particular route
                            </Form.Check.Label>
                          </Form.Check>
                          <Form.Check
                            className="d-flex align-items-baseline"
                            id="type-3"
                          >
                            <Form.Check.Input
                              {...register('goals')}
                              id="LEVEL_2_OR_3"
                              value="LEVEL_2_OR_3"
                              type="checkbox"
                            />
                            <Form.Check.Label
                              className="d-inline-block my-2 ps-2"
                              htmlFor="LEVEL_2_OR_3"
                            >
                              Bikeability Level 2-3
                            </Form.Check.Label>
                          </Form.Check>

                        </Form.Group>
                      </fieldset>
                    </Col>
                  </Row>

                  <Row className="pb-3">
                    <Col md={6}>
                      <Form.Group className="form-group">
                        <Form.Label>
                          My NSI/1st4Sport registration number:
                        </Form.Label>
                        <Form.Control
                          {...register('registration_number',{
                            maxLength: 20,
                            required: true
                          })}
                          type="text"
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                </div>
              </>
            )}

            {/* MARKETING QUESTION */}
            <Row className="pb-3">
              <Col md={12}>
                  <Form.Group className="mb-5">
                    <Form.Label>
                      Where did you learn about Cycle Buddies?
                    </Form.Label>
                    <Form.Control
                      {...register('marketing')}
                      className="mb-1"
                      as="textarea"
                      rows={3}
                    />
                  </Form.Group>
                </Col>
              </Row>

    </OnboardingForm>
  );
};

OnboardingGoals.defaultProps = {};

OnboardingGoals.propTypes = {
  riderType: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  state: PropTypes.object,
};

export default OnboardingGoals;

import React, { useEffect, useContext } from "react";
import {
  Badge,
  Card,
  Col,
  Container,
  Row,
  OverlayTrigger,
  Tooltip,
  Button,
} from "react-bootstrap";

import { useParams, NavLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { store } from "../Store";
import AuthContext from "../context/AuthContext";

import profileImageDefaultMed from "../assets/images/lcc-user-profile-default-360.png";
import profileImageDefaultLrg from "../assets/images/lcc-user-profile-default-720.png";

import { setChatIntent } from "../utils/chatIntent";

const PublicProfilePage = () => {
  const urlParams = useParams();
  const { dispatch } = store;
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const { publicProfile: profile, isLoading } = useSelector(({ buddies }) => {
    return buddies;
  });

  useEffect(() => {
    if (!profile) dispatch.buddies.getPublicProfileById(urlParams?.uid);
  }, [profile]);

  const isPreviewingOwnProfile = urlParams?.uid === user?.uid;

  if (isLoading) return <></>;

  if (!profile)
    return (
      <Container className="text-center mt-4">
        <h1 className="pb-1">No profile found</h1>

        <NavLink
          to="/login"
          className="action-button rounded-pill px-3 btn btn-primary"
        >
          Log In
        </NavLink>
      </Container>
    );

  return (
    <Container>
      <Card>
        <div className="d-block d-md-none">
          <img
            src={profile.profile_photo_med}
            srcSet={[
              `${profile.profile_photo_med} 1x`,
              `${profile.profile_photo_med} 2x`,
            ]}
            className="img-fluid rounded-top w-100"
            loading="lazy"
            alt=""
          />
        </div>
        <Card.Header className="px-3 py-3 px-md-5 py-md-4 g-2">
          <Row className="align-items-center g-3">
            <Col>
              <h1 className="h1">{profile.username}</h1>
            </Col>
            {!isPreviewingOwnProfile && (
              <Col md="auto">
                <Button
                  variant="primary"
                  size="lg"
                  className="w-100 w-sm-auto d-flex align-items-center gap-2"
                  onClick={() => {
                    setChatIntent(btoa(urlParams?.uid), Date.now());
                    navigate("/login");
                  }}
                >
                  <span className="material-symbols-sharp fs-4">chat</span> Log
                  in to chat with this user
                </Button>
              </Col>
            )}
          </Row>
          <Row className="my-3">
            <div className="d-flex flex-row flex-wrap gap-3 mt-1 align-items-start align-items-sm-center">
              {profile.rider_type === "INSTRUCTOR" && (
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip>This user is a verified instructor</Tooltip>
                  }
                >
                  <Badge
                    bg="dark"
                    text="white"
                    className="px-2 fw-bold d-flex align-items-center gap-1"
                  >
                    <span className="material-symbols-sharp fs-6">
                      verified
                    </span>{" "}
                    Instructor
                  </Badge>
                </OverlayTrigger>
              )}

              {profile.verified && (
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>This user has been DBS checked</Tooltip>}
                >
                  <Badge
                    bg="dark"
                    text="white"
                    className="px-2 fw-bold d-flex align-items-center gap-1"
                  >
                    <span className="material-symbols-sharp fs-6">
                      verified_user
                    </span>{" "}
                    DBS Checked
                  </Badge>
                </OverlayTrigger>
              )}
            </div>
          </Row>
        </Card.Header>
        <Card.Body className="px-3 py-3 px-md-5 py-md-4">
          <Row className="">
            <Col sm={12} lg={6}>
              {!!profile?.introduction && <p>{profile?.introduction}</p>}
              {!!profile?.badges.length && (
                <div className="my-4 align-items-start align-items-sm-center border-bottom my-4">
                  {profile.badges.map(function (badge, index) {
                    return (
                      <div className="border-top py-2">
                        <OverlayTrigger
                          key={index}
                          placement="top"
                          overlay={
                            badge.description ? (
                              <Tooltip>{badge.description}</Tooltip>
                            ) : (
                              <></>
                            )
                          }
                        >
                          <div className="d-inline-flex align-items-center gap-2">
                            <span className="material-symbols-sharp fs-4">
                              {badge.icon}
                            </span>{" "}
                            {badge.name}
                          </div>
                        </OverlayTrigger>
                      </div>
                    );
                  })}
                </div>
              )}
            </Col>
            <Col
              sm={12}
              lg={{ span: 5, offset: 1 }}
              className="d-none d-md-block"
            >
              <div className="">
                <img
                  src={profile.profile_photo_med || profileImageDefaultMed}
                  srcSet={[
                    `${profile.profile_photo_med || profileImageDefaultMed} 1x`,
                    `${profile.profile_photo_lrg || profileImageDefaultLrg} 2x`,
                  ]}
                  className="img-fluid rounded w-100"
                  loading="lazy"
                  alt=""
                />
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default PublicProfilePage;

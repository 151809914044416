import React, {useState, useContext} from "react";
import AuthContext from "../context/AuthContext";
import {Controller, useForm} from "react-hook-form";
import {Button, Col, Container, Form, Image, Row} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import logo from "../theme/assets/images/logo-full.png";
import 'swiper/components/navigation/navigation.scss';
import AuthenticationScreen from "../components/furniture/AuthenticationScreen";
import FormFieldErrorMessage from "../components/common/FormFieldErrorMessage";
import {getExternalFAQUrl} from "../utils/Utils";


const SignupPage = () => {

  const {registerUser} = useContext(AuthContext);
  const [status, setStatus] = useState(null);
  const {
    control,
    clearErrors,
    handleSubmit,
    formState: {errors ,isValid},
    isSubmitting,
    register,
    getValues,
    setError,
      setValue
  } = useForm({
    defaultValues : {
      email : '',
      password : '',
      first_name :'',
      last_name :'',
      username : ''
    }
  });

  const submitSignup = async (formData) => {

      try {

          await registerUser(formData);
          setStatus('success');
      } catch (error) {
        setStatus('error');

        // parse error response to extract specific field errors
        if (error.response && error.response.data) {
          for (const [key, value] of Object.entries(error.response.data)) {
            setError(key, {
              message: value
            });
          }
        } else {
          setError('other', {
            message: 'Error trying to send create account, please try again.'
          })
        }
      }
  }

  return (

    <AuthenticationScreen title="Sign up">
      { status === 'success' && <>
        <h3>Your account has been created</h3>
        <p>Let's complete your registration! Please click on the link in the email we've just sent you.</p>
        <p>If it hasn't arrived within a couple of minutes, please try again or contact us.</p>
      </> }
      { status !== 'success' && <>
        <p>Whether you’re a new cyclist, cyclist instructor, or want to be a cycling buddy expert.</p>
        <form
          className={`mt-4`}
          onSubmit={(e) => {
            e.preventDefault();
            console.log(getValues());
            if (getValues().agree_terms) {
              handleSubmit(submitSignup)()
            } else {
              setError('other', {
                message: 'Please accept our terms and conditions'
              })
            }
          }
        }
          noValidate={true}
        >
          <Row>
            <Col>
              <Form.Group className="form-group">
                <Form.Label>First Name</Form.Label>
                <Controller
                  name="first_name"
                  control={ control }
                  render={ ({field}) => <Form.Control
                    { ...field }
                    type="text"
                    className="mb-0"
                    placeholder="First name"
                    required
                  /> }
                />
                <FormFieldErrorMessage fieldError={errors?.first_name}  />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="form-group">
                <Form.Label>Last Name</Form.Label>
                <Controller
                  name="last_name"
                  control={control}
                  render={({field}) => <Form.Control
                    { ...field }
                    type="text"
                    className="mb-0"
                    placeholder="Last name"
                    required
                  />}
                />
                <FormFieldErrorMessage fieldError={errors?.last_name}  />
              </Form.Group>
            </Col>
          </Row>

          <Form.Group className="form-group">
            <Form.Label>Display name</Form.Label>
            <Controller
              name="username"
              control={control}
              render={({field}) => <Form.Control
                {...field}
                type="text"
                className="mb-0"
                placeholder="Add a name people will see"
                required
              />}
            />
            {!!errors?.username?.message && <p className="invalid-feedback d-block">{errors?.username?.message?.toString().replace('username' ,'display name')}</p>}
          </Form.Group>

          <Form.Group className="form-group">
            <Form.Label>Email address</Form.Label>
            <Controller
              name="email"
              control={control}
              render={({field}) => <Form.Control
                {...field}
                type="email"
                className="mb-0"
                placeholder="Enter email"
                required
              />}
            />
            <FormFieldErrorMessage fieldError={errors?.email}  />
          </Form.Group>


          <Form.Group className="form-group">
            <Form.Label>Password</Form.Label>
            <Controller
              name="password"
              control={control}
              render={({field}) => <Form.Control
                {...field}
                type="password"
                className="mb-0"
                placeholder="Password"
                required
              />}
            />
            <FormFieldErrorMessage fieldError={errors?.password} customBlankMessage="Please enter a valid email" />
          </Form.Group>


          <div className="d-flex flex-column flex-md-row flex-wrap align-items-stretch gap-2 align-items-baseline">
            <Form.Check className="d-inline-block mt-2 pt-1">
              <Form.Check.Input
                  {...register('agree_terms')}
                  type="checkbox" className="me-2"
                  required
                  id="agree_terms"
                  onChange={(e) => {
                    setValue('agree_terms',e.target.checked)
                    if (e.target.checked) {
                      clearErrors('other');
                    }
                  }}

                />
              <Form.Check.Label htmlFor="agree_terms">I accept the <a href={`${getExternalFAQUrl()}`} target="_blank" rel="noreferrer">Terms and Conditions</a></Form.Check.Label>
            </Form.Check>
            <Button
              type="submit"
              className="btn-primary rounded-pill ms-sm-auto px-3 mt-1">Sign Up</Button>
          </div>
          <FormFieldErrorMessage fieldError={errors?.other} customBlankMessage="Sorry, we can't create you an account right now." />
          <div className="sign-info">
            <span className="dark-color d-inline-block line-height-2">Already Have Account ? <Link to="/login">Log In</Link></span>
          </div>
        </form>
      </> }
    </AuthenticationScreen>
  )
}

export default SignupPage;
import React, { useContext } from "react";
import { AmityUiKitChat } from "@amityco/ui-kit-open-source";
import { useSearchParams } from "react-router-dom";
import axios from "axios";

import AuthContext from "../context/AuthContext";
import useMarkMessagesAsRead from "../hooks/useMarkMessagesAsRead";
import useGetChats from "../hooks/useGetChats";

const Chat = () => {
  const [searchParams] = useSearchParams();
  const { user } = useContext(AuthContext);
  const { onChannelSelect, isDeletedOrDisabled } = useMarkMessagesAsRead();
  const { chatActiveData } = useGetChats();

  const onSendMessage = (channelId, text) => {
    axios.post(`/api/v1/chats/${channelId}/send_message/`, {
      user_uid: user.uid,
      text: text,
    });
  };

  if (!!user) {
    return (
      <>
        <AmityUiKitChat
          defaultChannelId={searchParams.get("channelId")}
          onSendMessage={onSendMessage}
          onChannelSelect={onChannelSelect}
          isDeletedOrDisabled={isDeletedOrDisabled}
          chatActiveData={chatActiveData}
        />
      </>
    );
  } else {
    return <></>;
  }
};

export default Chat;

import { useState, useContext } from "react";
import {
  Button,
  Badge,
  Card,
  Col,
  Container,
  Form,
  Nav,
  Tab,
  Row,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { addLngLatLocations } from "../utils/locations";

//image
import img1 from "../theme/assets/images/user/11.png";
import SettingsProfile from "../components/settings/SettingsProfile";
import SettingsPassword from "../components/settings/SettingsPassword";
import SettingsAccount from "../components/settings/SettingsAccount";
import useUpdateSettings from "../hooks/useUpdateSettings";

const SettingsPage = () => {
  const [tabKey, setTabKey] = useState("first");

  const {
    changePassword,
    errors,
    control,
    handleSubmit,
    isValid,
    passwordControl,
    passwordErrors,
    passwordHandleSubmit,
    passwordRegister,
    register,
    resetField,
    submitSettings,
    user,
    watch,
    getValues,
  } = useUpdateSettings();

  return (
    <>
      <Container>
        <Tab.Container activeKey={tabKey} onSelect={(k) => setTabKey(k)}>
          <Row>
            <Col lg={12}>
              <Nav as="ul" variant="pills" className="iq-edit-profile">
                <Nav.Item as="li" className="col-4 p-0">
                  <Nav.Link eventKey="first" role="button">
                    Profile
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li" className="col-4 p-0">
                  <Nav.Link eventKey="second" role="button">
                    Password
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li" className="col-4 p-0">
                  <Nav.Link eventKey="third" role="button">
                    Account
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col lg={12}>
              <Tab.Content>
                <Tab.Pane eventKey="first" className="fade show">
                  <SettingsProfile
                    control={control}
                    isValid={isValid}
                    errors={errors}
                    onSubmit={handleSubmit(submitSettings)}
                    register={register}
                    resetField={resetField}
                    title="Profile"
                    user={user}
                    watch={watch}
                    getValues={getValues}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="second" className="fade show">
                  <SettingsPassword
                    errors={passwordErrors}
                    register={passwordRegister}
                    onSubmit={passwordHandleSubmit(changePassword)}
                    control={passwordControl}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="third" className="fade show">
                  <SettingsAccount
                    control={control}
                    errors={errors}
                    onSubmit={handleSubmit(submitSettings)}
                    register={register}
                    user={user}
                    setTabKey={setTabKey}
                    watch={watch}
                    getValues={getValues}
                  />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </>
  );
};

export default SettingsPage;

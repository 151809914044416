import React, { useEffect } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import OnboardingForm from "./OnboardingForm";
import {getExternalFAQUrl} from "../../utils/Utils";

const OnboardingProfile = ({
  handleSubmit,
  register,
  riderType,
  submitSettings,
  user,
}) => {
  let navigate = useNavigate();

  return (
    <OnboardingForm
      page={4}
      title="Profile"
      previousButton={{
        label: "Previous",
        onClick: () => navigate("/onboarding/locations"),
      }}
      nextButton={{
        label: "Save and proceed",
        onClick: () => {
          let handleErrors = (e) => {
            console.error(e);
          };
          handleSubmit(submitSettings, handleErrors)();
          navigate("/onboarding/organisation");
        },
      }}
    >
      <Form>
        <Form.Group controlId="formFile" className="mb-3">
          <Form.Label>Select a profile photo</Form.Label>
          <Form.Control type="file" {...register("profile_photo")} />
        </Form.Group>

        <Form.Group className="mb-5">
          <Form.Label>Introduce yourself to potential cycle buddies</Form.Label>
          <Form.Control
            {...register("introduction")}
            className="mb-1"
            as="textarea"
            rows={3}
          />
          <Form.Text muted>
            {/* NEW OR RETURNING */}
            {riderType === "NEW_RETURNING" && (
              <p>
                Not sure what put here? Check out the{" "}
                <a
                  href={`${getExternalFAQUrl()}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  tips in FAQs{" "}
                  <span className="material-symbols-outlined align-middle small">
                    open_in_new
                  </span>{" "}
                </a>{" "}
              </p>
            )}

            {/* EXPERIENCED */}
            {riderType === "EXPERIENCED" && (
              <p>
                Not sure what put here? Check out the{" "}
                <a
                  href={`${getExternalFAQUrl()}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  tips in FAQs{" "}
                  <span className="material-symbols-outlined align-middle small">
                    open_in_new
                  </span>{" "}
                </a>{" "}
              </p>
            )}

            {/* INSTRUCTOR */}
            {riderType === "INSTRUCTOR" && (
              <p>
                Not sure what put here? Check out the{" "}
                <a
                  href={`${getExternalFAQUrl()}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  tips in FAQs{" "}
                  <span className="material-symbols-outlined align-middle small">
                    open_in_new
                  </span>{" "}
                </a>{" "}
              </p>
            )}
          </Form.Text>
        </Form.Group>

        {riderType === "NEW_RETURNING" &&
          user?.gender &&
          user?.gender !== "PREFER_NOT_TO_SAY" && (
            <fieldset className="mb-5">
              <legend className="h4 pb-1">Matching</legend>
              <Form.Check className="d-flex align-items-baseline py-0">
                <Form.Check.Input
                  {...register("MATCH_TO_GENDER")}
                  type="checkbox"
                  id="MATCH_TO_GENDER"
                  required
                />
                <Form.Check.Label
                  className="d-inline-block my-2 ps-2"
                  htmlFor="MATCH_TO_GENDER"
                >
                  Only match me with others who{" "}
                  {user?.gender === "PREFER_TO_SELF_DESCRIBE"
                    ? user?.gender?.toLowerCase()?.replaceAll("_", " ")
                    : `are ${user?.gender?.toLowerCase()}`}{" "}
                </Form.Check.Label>
              </Form.Check>
            </fieldset>
          )}
      </Form>
    </OnboardingForm>
  );
};

OnboardingProfile.defaultProps = {};

OnboardingProfile.propTypes = {
  riderType: PropTypes.object,
};
export default OnboardingProfile;

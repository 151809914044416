import React from "react";
import Header from "./furniture/Header";
import Footer from "./furniture/Footer";
import {getExternalFAQUrl} from "../utils/Utils";

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <div className="main-content">
        <div id="content-page" className="content-page">
          {children}
          <div className="small text-center p-3 mt-auto">
            Stay safe: read our FAQ and {" "}
            <a href={`${getExternalFAQUrl()}`} target="_blank">
              safety guidelines{" "}
              <span
                className="material-symbols-outlined align-middle small"
                target="_blank"
                rel="noreferrer"
              >
                open_in_new
              </span>
            </a>
            .
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Layout;

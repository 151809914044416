import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import { useForm, Controller } from "react-hook-form";
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  Row,
  Spinner,
} from "react-bootstrap";
import logo from "../theme/assets/images/logo-full.png";
// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/components/navigation/navigation.scss";
import AuthenticationScreen from "../components/furniture/AuthenticationScreen";
import FormFieldErrorMessage from "../components/common/FormFieldErrorMessage";

const LoginPage = () => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const { loginUser, userInactive } = authContext;
  const [status, setStatus] = useState(null);
  const [requiresActivation, setRequiresActivation] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    isSubmitting,
    register,
    setError,
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  /* useEffect(() => {
    if (authContext?.user) {
      if (authContext?.user.rider_type === 'NEW_RETURNING') {
        navigate('/find-buddies');
      } else {
        navigate('/welcome');
      }
    }
  })*/

  const submitLogin = async (formData) => {
    try {
      await loginUser(formData.email, formData.password);
      if (authContext?.user?.rider_type === "NEW_RETURNING") {
        navigate("/find-buddies");
      } else {
        navigate("/welcome");
      }
    } catch (error) {
      console.error("error", error);
      if (userInactive(error)) {
        setRequiresActivation(true);
      } else {
        setStatus("error");

        // parse error response to extract specific field errors
        if (error.response && error.response.data) {
          for (const [key, value] of Object.entries(error.response.data)) {
            setError(key, { message: value });
          }
        } else {
          setError("non_field_errors", {
            message: "Error trying to login, please try again.",
          });
        }
      }
    }
  };

  return (
    <AuthenticationScreen title="Log in">
      <p>
        Welcome to LCC Cycle Buddies. Please use your email and password to log
        in.
      </p>

      <form
        className={`mt-4`}
        onSubmit={handleSubmit(submitLogin)}
        noValidate={true}
      >
        <input {...register("non_field_errors")} type="hidden" />

        <Form.Group className="form-group">
          <Form.Label>Email address</Form.Label>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Form.Control
                {...field}
                type="email"
                className="mb-0"
                placeholder="Enter email"
                required
                tabindex="1"
              />
            )}
          />
          <FormFieldErrorMessage
            fieldError={errors?.email}
            customBlankMessage="Please enter a valid email"
          />
        </Form.Group>

        <Form.Group className="form-group">
          <Form.Label>Password</Form.Label>
          <Link to="/password-reset/" className="float-end" tabindex="3">
            Forgot password?
          </Link>
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <Form.Control
                {...field}
                type="password"
                className="mb-0"
                placeholder="Password"
                required
                tabindex="2"
              />
            )}
          />
          <FormFieldErrorMessage fieldError={errors?.password} />
        </Form.Group>

        <div className="d-flex flex-column flex-md-row align-items-stretch gap-2 align-items-md-baseline">
          <Button
            variant="primary"
            type="submit"
            className="rounded-pill ms-sm-auto px-3 mt-1 order-2 text-nowrap"
          >
            {isSubmitting ? (
              <Spinner
                as="i"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Sign in"
            )}
          </Button>
          {!!errors?.non_field_errors?.message && (
            <p className="invalid-feedback d-block m-0">
              {errors?.non_field_errors?.message}
            </p>
          )}
          {requiresActivation && (
            <p className="invalid-feedback d-block m-0">
              Your account still needs to be activated, please check for signup
              email or{" "}
              <Link to="/resend-activation">resend activation email</Link>.
            </p>
          )}
        </div>
        <div className="sign-info">
          <span className="dark-color d-inline-block line-height-2">
            Don't have an account? <Link to="/signup">Sign up</Link>
          </span>
        </div>
      </form>
    </AuthenticationScreen>
  );
};

export default LoginPage;

import { ChannelRepository, ChannelFilter } from '@amityco/js-sdk';
import orderBy from 'lodash/orderBy';

import useLiveCollection from '~/core/hooks/useLiveCollection';
import {useEffect} from "react";

function useChannelsList() {
  const [channels, hasMore, loadMore,loadingFirstTime,loadingMore] = useLiveCollection(
    // Note: we can not use SDK sortBy LastActivity option - because by default it uses
    // ASC direction from BE. By default LastCreated is used. It still gives wrong result but it
    // better.
    () => ChannelRepository.queryChannels({ filter: ChannelFilter.Member }),
    [],
    undefined,
    true
  );

  useEffect(() => {
    console.log('CHANNELS HAS CHANGED', channels, hasMore, loadMore,loadingFirstTime,loadingMore);
  }, [channels,hasMore, loadMore,loadingFirstTime,loadingMore])


  return [orderBy(channels, 'lastActivity', 'desc'), hasMore, loadMore];
}

export default useChannelsList;

import React, { useEffect, useState } from "react";
import SettingsTab from "./SettingsTab";
import { Button, Form, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { phoneNumberRegex, postCodeRegex } from "../../utils/locations";

const SettingsAccount = ({
  control,
  errors,
  onSubmit,
  register,
  user,
  setTabKey,
  watch,
  getValues,
}) => {
  const [isValid, setIsValid] = useState(false);

  const watchFields = watch([
    "contact_postcode",
    "contact_street_address_1",
    "contact_city",
    "telephone",
  ]);

  useEffect(() => {
    const contactPostCode = getValues("contact_postcode");
    const contactStreetAddress1 = getValues("contact_street_address_1");
    const contactCity = getValues("contact_city");
    const telephone = getValues("telephone");

    const contactPostCodeValid = postCodeRegex.test(contactPostCode);

    // Missing data
    if (!contactPostCodeValid || !contactStreetAddress1 || !contactCity || !telephone) {
      setIsValid(false);
      return;
    }

    const telephoneValid = phoneNumberRegex.test(telephone.replace(/\s*/g, ""));

    if (!telephoneValid) {
      setIsValid(false);
      return;
    }

    setIsValid(true);
  }, [watchFields]);

  return (
    <SettingsTab title="Account">

      <Form onSubmit={onSubmit}>
        <Row className="align-items-center ">
          <Col md="12">
            <p className="mb-4">
              For safeguarding purposes, we need to know your address and contact details. This information will not be shared publicly, and would only be 
              used for project admin, user validation and security or safeguarding reasons.
            </p>
          </Col>
          <Col md="6">
            <Form.Group className="form-group">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                {...register("email")}
                type="text"
                id="email"
                name="email"
                required
              />
              <Form.Control.Feedback className="invalid">
                {errors.username && <>{errors.username.message}</>}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="form-group mt-4">
              <Form.Label>Address line 1</Form.Label>
              <Form.Control
                {...register("contact_street_address_1")}
                type="text"
                required
              />
            </Form.Group>

            <Form.Group className="form-group">
              <Form.Label>
                Address line 2 <span className="text-muted">— optional</span>
              </Form.Label>
              <Form.Control
                {...register("contact_street_address_2")}
                type="text"
              />
            </Form.Group>

            <Form.Group className="form-group">
              <Form.Label>City</Form.Label>
              <Form.Control {...register("contact_city")} type="text" required />
            </Form.Group>

            <Form.Group className="form-group">
              <Form.Label>Postcode</Form.Label>
              <Form.Control
                {...register("contact_postcode")}
                type="text"
                autoFocus
                className="mb-2"
                required
              />

            </Form.Group>

            <Form.Group className="form-group">
              <Form.Label>Mobile number</Form.Label>
              <Form.Control {...register("telephone")} type="tel" autoFocus />
            </Form.Group>
          </Col>
        </Row>

        {!isValid && (
          <div class="alert alert-warning my-4" role="alert">
            We now require contact details in case of emergency. Please fill in
            the required fields.
          </div>
        )}

        <div className="mb-3 mt-5 gap-2 d-flex flex-column flex-sm-row justify-content-between align-items-baseline">
          <a
            className="d-flex align-items-center gap-2"
            href={`mailto:${
              window.Config.REACT_APP_LCC_EMAIL
            }?Subject=${encodeURIComponent(
              `Delete account for ${user.email}`
            )}&Body=${encodeURIComponent(`Delete account for ${user.email}`)}`}
          >
            <span className="material-symbols-sharp">delete</span>
            Delete Account
          </a>
          <Button
            type="submit"
            variant="primary"
            name="update"
            className="action-button rounded-pill px-3"
            value="Update"
            disabled={!isValid}
          >
            Update
          </Button>
        </div>
      </Form>
    </SettingsTab>
  );
};

export default SettingsAccount;

import React, { useEffect } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import diversityQuestions, {
  isOtherFieldSetting,
} from "../../fixtures/diversityQuestions.js";
import PropTypes from "prop-types";
import OnboardingForm from "./OnboardingForm";

const OnboardingDiversity = ({
  handleSubmit,
  handleChange,
  register,
  riderType,
  setRiderType,
  setValue,
  state,
  submitSettings,
  user,
}) => {
  let navigate = useNavigate();

  // Census birth year Dropdown
  // assuming users are 17 < age  < 100

  const returnBirthYears = () => {
    const currentYear = new Date().getFullYear();
    const selectYears = [];

    for (let year = currentYear - 17; year > currentYear - 100; year--) {
      selectYears.push(
        <option value={year} key={`year-birth-${year}`}>
          {year}
        </option>
      );
    }

    return selectYears;
  };

  const isInViewport = (element) => {
    const rect = element.getBoundingClientRect();
    const html = document.documentElement;
    const masthead = document.querySelector(".iq-top-navbar");
    const mastheadHeight = masthead.offsetHeight;
    return (
      rect.top >= mastheadHeight &&
      rect.bottom <= (window.innerHeight || html.clientHeight)
    );
  };

  const scrollToQuestion = (answer) => {
    const parentFieldset = answer.closest("fieldset");
    const legend = parentFieldset.querySelector("legend:first-of-type");
    const masthead = document.querySelector(".iq-top-navbar");
    const mastheadHeight = masthead.offsetHeight;

    if (!isInViewport(legend)) {
      window.scrollTo({
        behavior: "smooth",
        top:
          parentFieldset.getBoundingClientRect().top +
          window.pageYOffset -
          mastheadHeight -
          20,
      });
    }
  };

  const resetDiversityQuestions = (target, name) => {
    scrollToQuestion(target);
    if (!!name) {
      diversityQuestions
        .find((q) => q.name === name)
        .answers.map((a) => {
          if (!!a.question) {
            setValue(a.question.name, null);
            setValue(a.question.fieldNameOther, null);
          }
          return a;
        });
    }
  };

  const resetDiversitySubQuestions = (target, name, parentName) => {
    scrollToQuestion(target);

    if (!!name) {
      diversityQuestions
        .find((q) => q.name === parentName)
        .answers?.find((a) => a?.question?.name)
        ?.question?.answers?.map((a) => {
          if (!!a.question) {
            setValue(a?.question?.name, null);
            setValue(a?.question?.fieldNameOther, null);
          }
        });
    }
  };

  useEffect(() => {
    if (user?.onboarded) {
      navigate("/find-buddies");
    }
  }, [user]);

  return (
    <OnboardingForm
      previousButton={{
        label: "Previous",
        onClick: () => navigate("/onboarding/disclaimer"),
      }}
      nextButton={{
        label: "Save and proceed",
        onClick: () => {
          let handleErrors = (e) => {
            console.error(e);
          };
          setValue("onboarded", true);
          handleSubmit(submitSettings, handleErrors)();
        },
      }}
      page={8}
      title="Diversity"
    >
      <>
        <div className="mb-5">
          <p>
            LCC has a responsibility to serve all the people in London but we
            suspect certain groups are under-represented in the organisation.
            Your answers will help us assess the nature and extent of this issue
            so we can develop plans to address it.
          </p>

          <p>
            We are collecting diversity data of LCC staff, trustees and our
            active volunteers membership so we can compare how diverse we are
            against London 2021 Census data. And then track how our activities
            to be more inclusive impact on the diversity of the organisation.
          </p>

          <p>
            Your data is held securely and will not be made public. Only
            aggregated data will be reported . You do not have to answer any
            question you feel uncomfortable sharing with us &#x2013; if this is
            the case then saying &#x2018;prefer not to say&#x2019; is useful
            information for us.
          </p>

          <p>
            LCC will not infringe your rights regarding personal data. You can{" "}
            <a
              href="https://lcc.org.uk/privacy-policy/"
              target="_blank"
              rel="noreferrer"
            >
              read our Privacy Policy here
            </a>
            . If you have any questions or feedback please get in touch with
            Stewart Dring, Cycling Projects Manager at{" "}
            <a href="mailto:stewart.dring@lcc.org.uk">
              stewart.dring@lcc.org.uk
            </a>{" "}
          </p>
        </div>
        <Form>
          {/* Birth year question*/}
          <Form.Group className="mb-5">
            <Form.Label className="h3 pb-1">Birth Year</Form.Label>
            <Form.Select
              {...register("birth_year")}
              required
              className="w-auto"
            >
              <option value={-1}>Select…</option>
              <option value={0}>Would rather not say</option>
              {returnBirthYears()}
            </Form.Select>
          </Form.Group>

          {/* First level question */}
          {!!diversityQuestions &&
            diversityQuestions.map(
              (
                {
                  answers,
                  fieldNameOther,
                  name,
                  questionLabel,
                  required,
                  type,
                },
                index
              ) => {
                return (
                  <fieldset
                    key={index}
                    className={
                      index === diversityQuestions.length - 1 ? "" : "pb-5"
                    }
                  >
                    <legend className="h3 pb-1">{questionLabel}</legend>
                    {!!answers && (
                      <>
                        {answers.map((answer) => {
                          return (
                            <div
                              key={`${name}_${answer.name}`}
                              className="mb-0"
                            >
                              <Form.Check
                                className="d-flex align-items-baseline py-0"
                                id={`${name}_${answer.name}`}
                              >
                                <Form.Check.Input
                                  {...register(name)}
                                  type={type}
                                  value={answer.name}
                                  required={required}
                                  onChange={(event) => {
                                    resetDiversityQuestions(event.target, name);
                                    setValue(
                                      fieldNameOther,
                                      isOtherFieldSetting(answer.name)
                                        ? user?.[fieldNameOther]
                                        : ""
                                    );
                                    handleChange(event);
                                  }}
                                  className="flex-shrink-0"
                                />
                                {/* Show text input if label is Other */}
                                {/* CHANGE TO ADDRESS POSSIBILITY OF MULTIPLE OTHER */}
                                <Form.Check.Label className="ps-2">
                                  {isOtherFieldSetting(answer.name) &&
                                  state[name] === answer.name ? (
                                    <Form.Control
                                      {...register(fieldNameOther)}
                                      type="text"
                                      className="w-auto"
                                      style={{ margin: "1px 0 px" }}
                                      placeholder={answer.label}
                                      autoFocus
                                    />
                                  ) : (
                                    <span className="d-block my-2">
                                      {answer.label}
                                    </span>
                                  )}
                                </Form.Check.Label>
                              </Form.Check>

                              {/* Second level question */}
                              {answer?.question && (
                                <fieldset
                                  className="ps-3 pb-3"
                                  aria-hidden={state[name] !== answer.name}
                                >
                                  {answer.question.showQuestion && (
                                    <legend className="h4 pt-2 pb-1">
                                      {answer.question.questionLabel}
                                    </legend>
                                  )}
                                  {answer?.question?.answers?.length > 0 &&
                                    answer.question.answers.map((sAnswer) => {
                                      return (
                                        <div
                                          key={`${name}_${answer.name}_${sAnswer.name}`}
                                        >
                                          <Form.Check
                                            className="d-flex align-items-baseline py-0"
                                            id={`${answer.name}_${sAnswer.name}`}
                                          >
                                            <Form.Check.Input
                                              {...register(
                                                answer.question.name
                                              )}
                                              type={answer.question.type}
                                              required={answer.required}
                                              value={sAnswer.name}
                                              onChange={(event) => {
                                                resetDiversitySubQuestions(
                                                  event.target,
                                                  sAnswer.name,
                                                  name
                                                );
                                                setValue(
                                                  answer.question
                                                    .fieldNameOther,
                                                  isOtherFieldSetting(
                                                    sAnswer.name
                                                  )
                                                    ? user?.[
                                                        answer.question
                                                          .fieldNameOther
                                                      ]
                                                    : ""
                                                );
                                                handleChange(event);
                                              }}
                                              className="flex-shrink-0"
                                            />
                                            <Form.Check.Label className="ps-2">
                                              {isOtherFieldSetting(
                                                sAnswer.name
                                              ) &&
                                              state[answer.question.name] ===
                                                sAnswer.name ? (
                                                <Form.Control
                                                  {...register(
                                                    answer.question
                                                      .fieldNameOther
                                                  )}
                                                  type="text"
                                                  className="w-auto"
                                                  style={{
                                                    margin: "2px 0 1px",
                                                  }}
                                                  placeholder="Other"
                                                  autoFocus
                                                />
                                              ) : (
                                                <span className="d-block my-2">
                                                  {sAnswer.label}
                                                </span>
                                              )}
                                            </Form.Check.Label>
                                          </Form.Check>
                                          {!!sAnswer.question && (
                                            <fieldset
                                              className="ps-4 pb-3"
                                              aria-hidden={
                                                state[answer.question.name] !==
                                                sAnswer.name
                                              }
                                            >
                                              {sAnswer.question
                                                .showQuestion && (
                                                <legend className="h4 pt-2 pb-1">
                                                  {
                                                    sAnswer.question
                                                      .questionLabel
                                                  }
                                                </legend>
                                              )}

                                              {/* Third level question */}
                                              {sAnswer?.question?.answers
                                                ?.length > 0 &&
                                                sAnswer.question.answers.map(
                                                  (ssAnswer) => {
                                                    return (
                                                      <div
                                                        key={`${name}_${answer.name}_${sAnswer.name}_${ssAnswer.name}`}
                                                      >
                                                        <Form.Check
                                                          className="d-flex align-items-baseline py-0"
                                                          id={`${name}_${answer.name}_${sAnswer.name}_${ssAnswer.name}`}
                                                        >
                                                          {
                                                            !!state?.[
                                                              sAnswer.question
                                                                .name
                                                            ]?.find(
                                                              (n) =>
                                                                n ===
                                                                ssAnswer.name
                                                            )
                                                          }
                                                          <Form.Check.Input
                                                            {...register(
                                                              sAnswer.question
                                                                .name
                                                            )}
                                                            type={
                                                              sAnswer.question
                                                                .type
                                                            }
                                                            value={
                                                              ssAnswer.name
                                                            }
                                                            required={
                                                              ssAnswer.required
                                                            }
                                                            onChange={(
                                                              event
                                                            ) => {
                                                              handleChange(
                                                                event,
                                                                true
                                                              );
                                                            }}
                                                            className="flex-shrink-0"
                                                          />
                                                          <Form.Check.Label className="ps-2">
                                                            {isOtherFieldSetting(
                                                              ssAnswer.name
                                                            ) &&
                                                            !!state?.[
                                                              sAnswer.question
                                                                .name
                                                            ]?.find(
                                                              (n) =>
                                                                n ===
                                                                ssAnswer.name
                                                            ) ? (
                                                              <Form.Control
                                                                type="text"
                                                                name={
                                                                  ssAnswer.name
                                                                }
                                                                className="w-auto"
                                                                style={{
                                                                  margin:
                                                                    "2px 0 1px",
                                                                }}
                                                                placeholder="Other"
                                                                autoFocus
                                                              />
                                                            ) : (
                                                              <span className="d-block my-2">
                                                                {ssAnswer.label}
                                                              </span>
                                                            )}
                                                          </Form.Check.Label>
                                                        </Form.Check>
                                                      </div>
                                                    );
                                                  }
                                                )}
                                            </fieldset>
                                          )}
                                        </div>
                                      );
                                    })}
                                </fieldset>
                              )}
                            </div>
                          );
                        })}
                      </>
                    )}
                  </fieldset>
                );
              }
            )}
        </Form>
      </>
    </OnboardingForm>
  );
};

OnboardingDiversity.defaultProps = {};

OnboardingDiversity.propTypes = {
  handleChange: PropTypes.func.isRequired,
  state: PropTypes.object,
};

export default OnboardingDiversity;

import React, {useState, useContext} from "react";
import AuthContext from "../context/AuthContext";
import {Controller, useForm} from "react-hook-form";
import AuthenticationScreen from "../components/furniture/AuthenticationScreen";
import {Button, Form} from "react-bootstrap";
import FormFieldErrorMessage from "../components/common/FormFieldErrorMessage";

const ResetPasswordPage = () => {
  const {resetPassword} = useContext(AuthContext);
  const [status, setStatus] = useState(null);
  const {control, register, handleSubmit, setError, formState: {errors}} = useForm();

  const submitReset = async (formData) => {
    try {
      await resetPassword(formData.email);
      setStatus('success');
    } catch (error) {
      setStatus('error');

      // parse error response to extract specific field errors
      if (error.response && error.response.data) {
        for (const [key, value] of Object.entries(error.response.data)) {
          setError(key, {
            message: value
          });
        }
      } else {
        setError('non_field_errors', {
          message: 'Error trying to send reset password email, please try again.'
        })
      }
    }
  };

  return (
    <AuthenticationScreen title="Reset Password">


      {status === 'success' && (
        <>
          <p className="pt-2"><b>Please click on the link in the email we've just sent you.</b></p>
          <p>If it hasn't arrived within a couple of minutes, please try again or contact us. Don't forget to check your spam email folder.</p>
          <p>You may safely close this page.</p>
        </>
      )}

      {status !== 'success' && (
        <>
          <form
            onSubmit={handleSubmit(submitReset)}
            noValidate={true}
            className="mt-4">
            <input
              {...register("non_field_errors")}
              type="hidden" />

            <Form.Group className="form-group">
              <Form.Label>Email address</Form.Label>
              <Controller
                name="email"
                control={control}
                render={({field}) => <Form.Control
                  {...field}
                  type="email"
                  className="mb-0"
                  placeholder="Enter email"
                  required
                />}
              />
              <FormFieldErrorMessage fieldError={errors?.email} />
            </Form.Group>

            <Button
              type="submit"
              className="btn-primary">Reset Password</Button>

          </form>
          {errors.non_field_errors && <p className="invalid-feedback d-block">{errors.non_field_errors.message}</p>}
          {status === 'error' && (
            <p className="invalid-feedback d-block">Please correct above errors.</p>
          )}
        </>
      )}
    </AuthenticationScreen>
  )
}

export default ResetPasswordPage;
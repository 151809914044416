import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import OnboardingForm from "./OnboardingForm";
import { phoneNumberRegex, postCodeRegex } from "../../utils/locations";

const OnboardingAddress = ({
  handleSubmit,
  register,
  riderType,
  submitSettings,
  user,
  getValues,
  watch,
}) => {
  const navigate = useNavigate();
  const [isContactPostcodeSet, setIsContactPostcodeSet] = useState(false);

  useEffect(() => {
    setIsContactPostcodeSet(!!getValues("contact_postcode"));
  }, []);

  const watchFields = watch([
    "contact_postcode",
    "contact_street_address_1",
    "contact_city",
    "telephone",
  ]);
  const [okToProceed, setOkToProceed] = useState(false);
  useEffect(() => {
    const contactPostCode = getValues("contact_postcode");
    const contactStreetAddress1 = getValues("contact_street_address_1");
    const contactCity = getValues("contact_city");
    const telephone = getValues("telephone");

    const contactPostCodeValid = postCodeRegex.test(contactPostCode);

    // Missing data
    if (!contactPostCodeValid || !contactStreetAddress1 || !contactCity || !telephone) {
      setOkToProceed(false);
      return;
    }

    const telephoneValid = phoneNumberRegex.test(telephone.replace(/\s*/g, ""));

    if (!telephoneValid) {
      setOkToProceed(false);
      return;
    }

    setOkToProceed(true);
  }, [watchFields]);

  return (
    <OnboardingForm
      page={6}
      title="Contact Details"
      previousButton={{
        label: "Previous",
        onClick: () => navigate("/onboarding/organisation"),
      }}
      nextButton={{
        label: "Save and proceed",
        onClick: () => {
          let handleErrors = (e) => {
            console.error(e);
          };
          handleSubmit(submitSettings, handleErrors)();
          navigate("/onboarding/disclaimer");
        },
        buttonEnabled: okToProceed,
      }}
    >
      <Form>
        <fieldset>
          <Row className="align-items-center ">
            <Col md="12">
              <p className="mb-3">
                For safeguarding purposes, we need to know your address and contact details. This information will not be shared publicly and would only be 
                used for project admin, user validation and security or safeguarding reasons.
              </p>
            </Col>
            <Col md="6">
              <Form.Group className="form-group mt-4">
                <Form.Label>Address line 1</Form.Label>
                <Form.Control
                  {...register("contact_street_address_1")}
                  type="text"
                  autoFocus
                />
              </Form.Group>

              <Form.Group className="form-group">
                <Form.Label>Address line 2 <span className="text-muted">— optional</span></Form.Label>
                <Form.Control
                  {...register("contact_street_address_2")}
                  type="text"
                  autoFocus
                />
              </Form.Group>

              <Form.Group className="form-group">
                <Form.Label>City</Form.Label>
                <Form.Control
                  {...register("contact_city")}
                  type="text"
                  autoFocus
                />
              </Form.Group>

              <Form.Group className="form-group">
                <Form.Label>Postcode</Form.Label>
                <Form.Control
                  {...register("contact_postcode")}
                  type="text"
                  autoFocus
                  className={isContactPostcodeSet && "mb-2"}
                />
  
              </Form.Group>

              <Form.Group className="form-group">
                <Form.Label>Mobile number</Form.Label>
                <Form.Control {...register("telephone")} type="tel" autoFocus />
              </Form.Group>
            </Col>
          </Row>
        </fieldset>
      </Form>
    </OnboardingForm>
  );
};

OnboardingAddress.defaultProps = {};

OnboardingAddress.propTypes = {
  riderType: PropTypes.object,
};
export default OnboardingAddress;

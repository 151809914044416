import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import OnboardingForm from "./OnboardingForm";
import { usePartnershipOrganisations } from "../../hooks/usePartnershipOrganisations";

const OnboardingOrganisation = ({
  handleSubmit,
  register,
  riderType,
  submitSettings,
  user,
}) => {
  let navigate = useNavigate();

  const { partnershipOrganisations, error } = usePartnershipOrganisations();

  // // use axios to get the list of organisations from given endpoint
  // const [partnershipOrganisations, setPartnershipOrganisations] = useState([]);
  // useEffect(() => {
  //     axios.get('/api/v1/auth/partnership_organisations')
  //     .then(response => {
  //         setPartnershipOrganisations(response.data);
  //     })
  //     .catch(error => {
  //         console.error(error);
  //     });
  // }, []);

  return (
    <OnboardingForm
      page={5}
      title="Organisation"
      previousButton={{
        label: "Previous",
        onClick: () => navigate("/onboarding/profile"),
      }}
      nextButton={{
        label: "Save and proceed",
        onClick: () => {
          let handleErrors = (e) => {
            console.error(e);
          };
          handleSubmit(submitSettings, handleErrors)();
          navigate("/onboarding/address");
        },
      }}
    >
      <Form>
        <Form.Group controlId="formOrganisation" className="mb-3">
          <Form.Label>
            Do you work for one of the following organisations?
          </Form.Label>

          <Form.Select
            {...register("organisation")}
            aria-label="Organisation"
            align="end"
            className="w-auto"
            required
            style={{ minWidth: "300px" }}
          >
            <option value="">No</option>
            {
              // map through the list of organisations and display them as options
              partnershipOrganisations?.map((organisation) => {
                return (
                  <option value={organisation.id} key={organisation.id}>
                    {organisation.name}
                  </option>
                );
              })
            }
          </Form.Select>
        </Form.Group>
      </Form>
    </OnboardingForm>
  );
};

OnboardingOrganisation.defaultProps = {};

OnboardingOrganisation.propTypes = {
  riderType: PropTypes.object,
};
export default OnboardingOrganisation;

import React, {
  useEffect,
  createContext,
  useContext,
  useState,
  useRef,
} from "react";
import {
  AmityUiKitProvider,
  useAmityUser,
  useAmitySDK,
  ChannelFilter,
  ChannelRepository,
} from "@amityco/ui-kit-open-source";
import axios from "axios";
import { useLocation } from "react-router-dom";

import AuthContext from "../context/AuthContext";

import user1 from "../assets/images/lcc-user-profile-default.svg";
import { limitDisplayName } from "../utils/Utils";

const ChatContext = createContext();

const lccTheme = {
  palette: {
    alert: "#dd300e",
    base: "#2B2B2B",
    primary: "#e30613",
    secondary: "#FFD400",
    tertiary: "#FF305A",
    neutral: "#17181C",
    highlight: "#1054DE",
    system: {
      borders: "#ebecef",
      background: "#fff",
    },
  },

  typography: {
    global: {
      fontFamily:
        "Open Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji",
      fontStyle: "normal",
    },
    headline: {
      fontWeight: 600,
      fontSize: "20px",
    },
    title: {
      fontWeight: 600,
      fontSize: "16px",
    },
    body: {
      fontWeight: "normal",
      fontSize: "16px",
    },
    bodyBold: {
      fontWeight: 600,
      fontSize: "16px",
    },
    caption: {
      fontWeight: "normal",
      fontSize: "12px",
    },
    captionBold: {
      fontWeight: 600,
      fontSize: "12px",
    },
  },
};

const DISPLAY_NAME_LIMIT = 50;

export const ChatProvider = ({ children }) => {
  const ref = useRef({
    isFetching: false,
    selectedChannelId: null,
  });
  const location = useLocation();
  const { user, userChatConfig } = useContext(AuthContext);
  const [channelUnreadCount, setChannelUnreadCount] = useState(0);
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
  const [isConnected, setIsConnected] = useState(false);

  const handleConnected = () => {
    setIsConnected(true);
  };

  // Check location for navigation away from the chat page
  useEffect(() => {
    if (location.pathname !== "/chat") {
      // reset selected chennel id
      ref.current.selectedChannelId = null;
    }
  }, [location]);

  // TODO: Refactor this into a services utility
  const markChannelAsRead = async (channelId) => {
    return axios.post(`/api/v1/chats/${channelId}/mark_seen/`);
  };

  const getChats = async (channelId) => {
    return axios.get(`/api/v1/chats`);
  };

  const onChannelSelect = ({ channelId }) => {
    ref.current.selectedChannelId = channelId;
    markChannelAsRead(channelId);
  };

  const onChannelsHaveChanged = async (channels) => {
    if (!ref.current.selectedChannelId || ref.current.isFetching) return;

    // Check if the current channel has new messages
    const currentChannelHasUnreadMessages = channels.some(
      (channel) =>
        channel.channelId === ref.current.selectedChannelId &&
        channel.unreadCount > 0
    );

    if (currentChannelHasUnreadMessages) {
      try {
        ref.current.isFetching = true;
        await markChannelAsRead(ref.current.selectedChannelId);
        ref.current.isFetching = false;
      } catch (e) {
        ref.current.isFetching = false;
      }
    }
  };

  // TODO: this should only be called once but being called multiple times but might just be due to StrictMode
  const SyncAvatar = ({ userId, avatarUrl }) => {
    const { client } = useAmitySDK();
    const { user: amityUser } = useAmityUser(userId);

    useEffect(() => {
      if (!amityUser.userId) {
        return;
      }

      if (user.avatarCustomUrl !== avatarUrl) {
        client.updateCurrentUser({ avatarCustomUrl: avatarUrl });
      }
    }, [amityUser.userId, avatarUrl]);
  };

  const getUnreadChannelCount = (channels) => {
    const channelUnreadCount = channels?.reduce((a, m) => m.unreadCount + a, 0);
    const hasUnreadMessages = channelUnreadCount > 0;

    setChannelUnreadCount(channelUnreadCount);
    setHasUnreadMessages(hasUnreadMessages);
  };

  // Assuming this has been done like this because useAmitySdk needs to be wrapped in the UI Kit Provider
  // I recomend refacoring the UI Kit Provider outside of this component and then moving both Sync Avatar and Get Channels
  // out of components and into the general context logic
  const GetChannels = ({ userId }) => {
    const { user: amityUser } = useAmityUser(userId);

    useEffect(() => {
      if (!amityUser.userId) {
        return;
      }

      const liveCollection = ChannelRepository.queryChannels({
        filter: ChannelFilter.Member,
      });

      getUnreadChannelCount(liveCollection.models);

      liveCollection.on("dataUpdated", (models) => {
        getUnreadChannelCount(models);
        onChannelsHaveChanged(models);
      });
    }, [amityUser.userId]);
  };

  // limit the username to 50 characters otherwise the causes an error in the UI Kit
  const displayName = limitDisplayName(
    user && user.username,
    DISPLAY_NAME_LIMIT
  );

  return (
    <ChatContext.Provider
      value={{
        channelUnreadCount,
        hasUnreadMessages,
        onChannelSelect,
        getChats,
        isConnected,
      }}
    >
      <div id="lcchat">
        {!!user && !!userChatConfig ? (
          <AmityUiKitProvider
            apiKey={userChatConfig.apiKey}
            authToken={userChatConfig.authToken}
            apiEndpoint={userChatConfig.apiUrl}
            apiRegion={userChatConfig.apiRegion}
            userId={user.uid}
            displayName={displayName}
            theme={lccTheme}
            onConnected={handleConnected}
          >
            <SyncAvatar
              avatarUrl={
                !!user?.profile_photo_sml ? user?.profile_photo_sml : user1
              }
              userId={user.uid}
            />
            <GetChannels userId={user.uid} />

            {children}
          </AmityUiKitProvider>
        ) : (
          <>{children}</>
        )}
      </div>
    </ChatContext.Provider>
  );
};

export default ChatContext;

import React from "react";
import { Button, Card, Form } from "react-bootstrap";
import PropTypes from "prop-types";

const OnboardingForm = ({
  children,
  nextButton,
  page,
  previousButton,
  title,
}) => {
  return (
    <Form className="mt-1">
      <Card>
        <Card.Header className="d-flex justify-content-between px-3 py-3 px-md-5 py-md-4">
          <div className="header-title">
            <h2 className="card-title">{title}</h2>
          </div>
          <div className="h2 text-muted">{page}/8</div>
        </Card.Header>
        <Card.Body className="px-3 py-2 px-md-5 py-md-4">
          <div className="form-card text-start">{children}</div>
        </Card.Body>
        {!!nextButton && (
          <Card.Footer className="px-3 py-3 px-md-5 py-md-5 d-flex flex-row-reverse justify-content-between border-top">
            {!!nextButton && (
              <Button
                variant="primary"
                name="next"
                className="next action-button float-end rounded-pill px-3"
                value={nextButton.label}
                onClick={nextButton.onClick}
                disabled={(() => {
                  if (nextButton.buttonEnabled !== undefined) {
                    return !nextButton.buttonEnabled;
                  } else {
                    return false;
                  }
                })()}
              >
                {nextButton.label}
              </Button>
            )}

            {!!previousButton && (
              <Button
                variant="secondary"
                name="previous"
                className="previous action-button-previous float-end me-3 rounded-pill px-3"
                value={previousButton.label}
                onClick={previousButton.onClick}
              >
                Previous
              </Button>
            )}
          </Card.Footer>
        )}
      </Card>
    </Form>
  );
};

OnboardingForm.defaultProps = {
  nextButton: false,
  previousButton: false,
};

OnboardingForm.propTypes = {
  nextButton: PropTypes.oneOf([
    PropTypes.shape(
      {
        buttonEnabled: PropTypes.bool,
        label: PropTypes.string,
        onClick: PropTypes.func,
      },
      PropTypes.bool
    ),
  ]),
  page: PropTypes.number.isRequired,
  previousButton: PropTypes.oneOf([
    PropTypes.shape(
      {
        label: PropTypes.string,
        onClick: PropTypes.func,
      },
      PropTypes.bool
    ),
  ]),
  title: PropTypes.string.isRequired,
};

export default OnboardingForm;

import React, {useContext, useEffect} from "react";
import {Link, Navigate, useNavigate} from "react-router-dom";
import { Container, Col, Image, Row } from "react-bootstrap";
import logo from "../assets/images/lcc-logo-white.svg";
import "../styles/pages/_homepage.scss"
import Footer from "../components/furniture/Footer";
import AuthContext from "../context/AuthContext";
import useIsLoggedIn from "../hooks/useIsLoggedIn";

const HomePage = () => {

  const { isLoggedIn, user, hasCalledLoginAPI } = useIsLoggedIn();
  console.log({isLoggedIn},{user},{hasCalledLoginAPI});

  if ( hasCalledLoginAPI) {

    if (isLoggedIn) {
      if ( user?.onboarded) {
        if (user.rider_type === 'NEW_RETURNING') {
          return <Navigate to={'/find-buddies'} />
        } else {
          return <Navigate to={'/welcome'} />
        }
      } else {
        return <Navigate to={'/onboarding'} />
      }
    } else {
      return (
        <>
          <section className="homepage bg-black min-vh-100 pb-md-4 d-flex flex-column">
            <Container fluid>
              <Row className="">
                <Col>
                  <div className="mx-1 my-4 mx-md-2 my-md-4">
                    <Image
                      src={logo}
                      className="img-fluid d-none d-md-block"
                      alt="logo"
                      style={{maxWidth: "200px"}}
                    />
                    <Image
                      src={logo}
                      className="img-fluid d-md-none"
                      alt="logo"
                      style={{maxWidth: "140px"}}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="d-none d-md-block">
                <Col>
                  <div
                    className="bg-primary text-white d-flex flex-column justify-content-center"
                    style={{
                      height: "720px",
                      width: "720px",
                      padding: "100px",
                      borderRadius: "100%",
                      fontWeight: 600,
                      marginInlineStart: "100px",
                    }}
                  >
                    <h1 className="text-white" style={{fontSize: '70px'}}>Cycle Buddies</h1>
                    <p>
                      Connecting new and experienced cyclists – help and support to
                      start cycling, build confidence, or take on new routes.
                    </p>
                    <div
                      className="d-flex flex-column flex-sm-row gap-2"
                      style={{fontSize: "2em"}}
                    >
                      <Link
                        to="/signup"
                        className="btn btn-primary bg-white text-primary lg rounded-pill px-3 mt-1"
                      >
                        Sign up
                      </Link>
                      <Link
                        to="/login"
                        className="btn btn-primary border border-white lg rounded-pill px-3 mt-1"
                      >
                        Log in
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
            <Container fluid className="bg-primary py-5 mt-auto d-md-none">
              <Row>
                <Col>
                  <div
                    className="text-white"
                    style={{fontWeight: 600}}
                  >
                    <h1 className="text-white" style={{fontSize: '60px'}}>Cycle Buddies</h1>
                    <p>
                      Connecting new and experienced cyclists – help and support to
                      start cycling, build confidence, or take on new routes.
                    </p>
                    <div
                      className="d-flex flex-column flex-sm-row gap-2"
                      style={{fontSize: "2em"}}
                    >
                      <Link
                        to="/signup"
                        className="btn btn-primary bg-white text-primary lg rounded-pill px-3 mt-1"
                      >
                        Sign up
                      </Link>
                      <Link
                        to="/login"
                        className="btn btn-primary border border-white lg rounded-pill px-3 mt-1"
                      >
                        Log in
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <Footer/>
        </>)
    }
  } else {
    return <></>
  }

};

export default HomePage;

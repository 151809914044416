import React, { useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import CustomToggle from "../../theme/components/dropdowns";
import user1 from "../../assets/images/lcc-user-profile-default.svg";
import logo from "../../assets/images/lcc-logo-white.svg";
import {
  Card,
  CloseButton,
  Container,
  Dropdown,
  Image,
  Nav,
  Navbar,
  Offcanvas,
} from "react-bootstrap";
import AuthContext from "../../context/AuthContext";
import useGetUnreadChannelCount from "../../hooks/useGetUnreadChannelCount";

const Header = () => {
  const [show1, setShow1] = useState(false);
  const { logoutUser, user } = useContext(AuthContext);
  const { pathname } = useLocation();

  const { channelUnreadCount, hasUnreadMessages } = useGetUnreadChannelCount();

  const handleLogout = async () => {
    await logoutUser();
  };

  return (
    <>
      <div className="iq-top-navbar">
        <Navbar
          expand="lg"
          variant="light"
          className="nav iq-navbar px-1 py-m-0 bg-primary"
        >
          <Container
            fluid
            className="navbar-inner justify-content-start justify-content-md-between"
          >
            <div className="d-none d-md-flex py-2">
              <Link
                to="/find-buddies"
                className="d-flex align-items-stretch iq-header-logo "
              >
                <Image
                  src={logo}
                  className=""
                  alt="logo"
                  style={{ maxWidth: "200px" }}
                  width="200"
                  height="80"
                />
                <div
                  className="h1 logo-title d-none d-md-flex align-items-center text-white border-start border-white ps-3 ms-3"
                  data-setting="app_name"
                >
                  Cycle Buddies
                </div>
              </Link>
            </div>

            <div className="d-flex d-md-none">
              <Link
                to="/"
                className="d-flex align-items-stretch iq-header-logo"
              >
                <Image
                  src={logo}
                  className=""
                  alt="logo"
                  width="100"
                  height="40"
                />
                <div
                  className="h2 logo-title d-flex d-md-none text-white align-items-center border-start border-white ps-3 ms-3"
                  data-setting="app_name"
                >
                  Cycle Buddies
                </div>
              </Link>
            </div>
            {!!user && (
              <Link
                className="d-lg-none d-flex ms-auto "
                to="#"
                aria-expanded={show1}
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                onClick={() => setShow1(!show1)}
              >
                <span className="material-symbols-sharp text-white">menu</span>
              </Link>
            )}
            <div
              className={`offcanvas offcanvas-end iq-profile-menu-responsive bg-primary ${
                show1 === true ? "show" : ""
              } `}
              tabIndex="-1"
              id="offcanvasBottom"
              style={{ visibility: `${show1 === true ? "visible" : "hidden"}` }}
            >
              {user && (
                <>
                  <Offcanvas.Header>
                    <h5 id="offcanvasRightLabel" className="text-white">
                      Menu
                    </h5>
                    <CloseButton
                      variant="white"
                      onClick={() => setShow1(false)}
                    />
                  </Offcanvas.Header>
                  <Offcanvas.Body className="pt-0">
                    <ul className="navbar-nav ms-auto navbar-list">
                      {user.onboarded && (
                        <>
                          {user.rider_type === "NEW_RETURNING" && (
                            <Nav.Item as="li">
                              <Link
                                to="/find-buddies"
                                className="d-flex align-items-center text-white"
                                onClick={() => setShow1(!show1)}
                              >
                                <i
                                  className={`material-symbols-sharp ${
                                    pathname === "/find-buddies" ? "filled" : ""
                                  }`}
                                >
                                  search
                                </i>
                                <span className="mobile-text d-lg-none ms-3">
                                  Find Buddies
                                </span>
                              </Link>
                            </Nav.Item>
                          )}

                          <Nav.Item as="li">
                            <Link
                              to="/chat"
                              className="d-flex align-items-center text-white"
                              onClick={() => setShow1(!show1)}
                            >
                              <i
                                className={`material-symbols-sharp ${
                                  pathname === "/chat" ? "filled" : ""
                                }`}
                              >
                                chat
                              </i>

                              {hasUnreadMessages && (
                                <>
                                  <span
                                    className="position-absolute translate-middle p-1 bg-success border border-2 border-white rounded-circle d-none d-lg-block "
                                    style={{ top: "1.5em", right: ".25em" }}
                                  >
                                    <span className="visually-hidden">
                                      {channelUnreadCount} New message(s)
                                    </span>
                                  </span>
                                  <span className="float-end ms-auto badge p-2 rounded-circle bg-success border border-2 border-white d-lg-none order-2">
                                    <span className="visually-hidden">
                                      {channelUnreadCount} New message(s)
                                    </span>
                                  </span>
                                </>
                              )}

                              <span className="mobile-text d-lg-none ms-3">
                                Messages
                              </span>
                            </Link>
                          </Nav.Item>
                          <Nav.Item as="li" className="d-lg-none">
                            <Link
                              to="/settings"
                              className="d-flex align-items-center text-white"
                              onClick={() => setShow1(!show1)}
                            >
                              <i className="material-symbols-sharp">
                                manage_accounts
                              </i>
                              <span className="mobile-text  ms-3">
                                Settings
                              </span>
                            </Link>
                          </Nav.Item>
                        </>
                      )}

                      <div className="d-flex d-lg-none align-items-center iq-sub-card border-0 position-relative text-white">
                        <span className="material-symbols-sharp">login</span>
                        <div className="ms-3">
                          <span
                            onClick={handleLogout}
                            className="mb-0 stretched-link"
                          >
                            Sign out
                          </span>
                        </div>
                      </div>

                      <Dropdown as="li" className="nav-item d-none d-lg-block">
                        <Dropdown.Toggle
                          href="#"
                          as={CustomToggle}
                          variant="d-flex align-items-center"
                        >
                          <Image
                            src={
                              !!user?.profile_photo_sml
                                ? user?.profile_photo_sml
                                : user1
                            }
                            className="img-fluid rounded-circle me-2"
                            alt="user"
                            loading="lazy"
                          />
                          <div className="fw-bold text-white">
                            <span>{user?.first_name}</span>
                          </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="sub-drop caption-menu shadow">
                          <Card className="shadow-sm m-0">
                            <Card.Header>
                              <div className="header-title">
                                <span className="mb-0">
                                  Hello, {user?.first_name} {user?.last_name}
                                </span>
                              </div>
                            </Card.Header>
                            <Card.Body className="p-0">
                              {user.onboarded && (
                                <div className="d-flex align-items-center iq-sub-card border-0 position-relative">
                                  <span className="material-symbols-sharp">
                                    manage_accounts
                                  </span>
                                  <div className="ms-3">
                                    <Link
                                      to="/settings"
                                      className="mb-0 stretched-link"
                                    >
                                      Settings
                                    </Link>
                                  </div>
                                </div>
                              )}
                              <div className="d-flex align-items-center iq-sub-card border-0 position-relative">
                                <span className="material-symbols-sharp">
                                  login
                                </span>
                                <div className="ms-3">
                                  <span
                                    onClick={handleLogout}
                                    className="mb-0 stretched-link"
                                  >
                                    Sign out
                                  </span>
                                </div>
                              </div>
                            </Card.Body>
                          </Card>
                        </Dropdown.Menu>
                      </Dropdown>
                    </ul>
                  </Offcanvas.Body>
                </>
              )}
            </div>
          </Container>
        </Navbar>
      </div>
    </>
  );
};

export default Header;

import { useContext, useEffect, useState } from "react";

import ChatContext from "../context/ChatContext";

const useGetChats = () => {
  const { getChats } = useContext(ChatContext);
  const [chatActiveData, setChatActiveData] = useState();

  useEffect(() => {
    async function fetchData() {
      const response = await getChats();
      setChatActiveData(
        response.data.reduce(
          (obj, chat) => ({
            ...obj,
            [chat.channel_id]: chat.members.length === 2,
          }),
          {}
        )
      );
    }
    fetchData();
  }, []);

  return { chatActiveData };
};

export default useGetChats;

import {useState, useContext} from "react";
import AuthContext from "../context/AuthContext";
import {useForm} from "react-hook-form";
import {Button, Form} from 'react-bootstrap'
import AuthenticationScreen from "../components/furniture/AuthenticationScreen";

const ResendActivationPage = () => {

  const {resendActivation} = useContext(AuthContext);
  const [status, setStatus] = useState(null);
  const {register, handleSubmit, setError, formState: {errors}} = useForm();

  const submitReactivation = async (formData) => {
    try {
      await resendActivation(formData.email);
      setStatus('success');
    } catch (error) {
      setStatus('error');

      // parse error response to extract specific field errors
      if (error.response && error.response.data) {
        for (const [key, value] of Object.entries(error.response.data)) {
          setError(key, {
            message: value
          });
        }
      } else {
        setError('non_field_errors', {
          message: 'Error trying to send reactivation email, please try again.'
        })
      }
    }
  };

  return (
    <AuthenticationScreen title="Resend Activation Email">


      {status === 'success' && (
        <>
          <h2>Email sent</h2>

          <p>
            Please follow link sent to your email to complete signup.
          </p>

          <p>
            Please try again or contact us if you do not receive it within a few
            minutes.
          </p>
        </>
      )}

      {status !== 'success' && (
        <>
          <form onSubmit={handleSubmit(submitReactivation)}>
          <Form.Group className="form-group">
            <Form.Label>Email address</Form.Label>

            <Form.Control 
                {...register("email")}
                type="text"
                id="email"
                name="email"
                placeholder="Enter email"
                required
              />

              {errors.email && <p className="error">{errors.email.message}</p>}
             </Form.Group>

             <Form.Control.Feedback type="invalid">{errors.non_field_errors && <>{errors.non_field_errors.message}</>}</Form.Control.Feedback>

            <Button variant="primary" type="submit"
            className="rounded-pill ms-sm-auto px-3 mt-1 order-2 text-nowrap">
            Resend</Button>
          </form>

          {status === 'error' && (
            <p className="error">Please correct above errors.</p>
          )}
        </>
      )}
    </AuthenticationScreen>
  )
}

export default ResendActivationPage;
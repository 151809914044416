import { useContext } from "react";
import ChatContext from "../context/ChatContext";

const useGetUnreadChannelCount = () => {
  const { channelUnreadCount, hasUnreadMessages } = useContext(ChatContext);

  return { channelUnreadCount, hasUnreadMessages };
};

export default useGetUnreadChannelCount;

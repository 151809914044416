import { useContext } from "react";

import ChatContext from "../context/ChatContext";

const useMarkMessagesAsRead = () => {
  const { onChannelSelect, isDeletedOrDisabled } = useContext(ChatContext);
  return { onChannelSelect, isDeletedOrDisabled };
};

export default useMarkMessagesAsRead;

import React, { useState, useContext, useEffect } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { Routes, Route, useNavigate } from "react-router-dom";
// import { store, history } from "../../Store";

// Steps
import OnboardingAbility from "../components/onboarding/OnboardingAbility";
import OnboardingGoals from "../components/onboarding/OnboardingGoals";
import OnboardingLocations from "../components/onboarding/OnboardingLocations";
import OnboardingDiversity from "../components/onboarding/OnboardingDiversity";
import OnboardingProfile from "../components/onboarding/OnboardingProfile";
import OnboardingOrganisation from "../components/onboarding/OnboardingOrganisation";
import OnboardingDisclaimer from "../components/onboarding/OnboardingDisclaimer";
import useUpdateSettings from "../hooks/useUpdateSettings";
import AuthContext from "../context/AuthContext";
import OnboardingAddress from "../components/onboarding/OnboardingAddress";

const Onboarding = () => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);

  const {
    errors,
    control,
    getValues,
    handleSubmit,
    isValid,
    register,
    setValue,
    submitSettings,
    user,
    watch,
  } = useUpdateSettings();

  const [state, setState] = useState({
    location_home: user?.home_postcode !== "",
    location_work: user?.work_postcode !== "",
    ethnic_group: user.ethnic_group,
    ethnic_group_white: user.ethnic_group_white,
    gender: user.gender,
    sexual_orientation: user.sexual_orientation,
    disability: user.disability,
    access: user.access,
    requirements_access_needed: user.requirements_access_needed,
    requirements_access_needs: user.requirements_access_needs,
  });

  const [riderType, setRiderType] = useState(user?.rider_type);

  function handleChange(e, boolean) {
    const { type, name, checked, value } = e.target;
    let _value = value;

    if (boolean) {
      if (checked) {
        _value = [...state[name], value];
      } else {
        _value = Array.from(state[name]).filter((v) => v !== value);
      }
    } else if (type === "checkbox") {
      _value = checked;
    }

    setState({
      ...state,
      [name]: _value,
    });
  }

  useEffect(() => {
    if (authContext?.user.onboarded) {
      if (authContext?.user.rider_type === "NEW_RETURNING") {
        navigate("/find-buddies");
      } else {
        navigate("/welcome");
      }
    }
  });

  return (
    <>
      <Container>
        <Row>
          <Col sm={12} lg={12}>
            <Routes>
              {/* ----- Step 1: Ability -----*/}
              <Route
                path=""
                element={
                  <OnboardingAbility
                    handleSubmit={handleSubmit}
                    register={register}
                    riderType={riderType}
                    setRiderType={setRiderType}
                    setValue={setValue}
                    submitSettings={submitSettings}
                    user={user}
                  />
                }
              />

              {/* ----- Step 2: Goals -----*/}
              <Route
                path="goals"
                element={
                  <OnboardingGoals
                    getValues={getValues}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    register={register}
                    riderType={riderType}
                    setValue={setValue}
                    state={state}
                    submitSettings={submitSettings}
                    user={user}
                    watch={watch}
                  />
                }
              />

              {/* ----- Step 3: Locations -----*/}
              <Route
                path="locations"
                element={
                  <OnboardingLocations
                    getValues={getValues}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    register={register}
                    riderType={riderType}
                    setValue={setValue}
                    state={state}
                    submitSettings={submitSettings}
                    user={user}
                    watch={watch}
                  />
                }
              />

              {/* ----- Step 4: Profile -----*/}
              <Route
                path="profile"
                element={
                  <Form className="mt-1">
                    <OnboardingProfile
                      riderType={riderType}
                      handleChange={handleChange}
                      handleSubmit={handleSubmit}
                      register={register}
                      submitSettings={submitSettings}
                      user={user}
                    />
                  </Form>
                }
              />

              {/* ----- Step 5: Organisation -----*/}
              <Route
                path="organisation"
                element={
                  <Form className="mt-1">
                    <OnboardingOrganisation
                      riderType={riderType}
                      handleChange={handleChange}
                      handleSubmit={handleSubmit}
                      register={register}
                      submitSettings={submitSettings}
                      user={user}
                    />
                  </Form>
                }
              />

              {/* ----- Step 6: Address -----*/}
              <Route
                path="address"
                element={
                  <OnboardingAddress
                    getValues={getValues}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    register={register}
                    riderType={riderType}
                    setValue={setValue}
                    state={state}
                    submitSettings={submitSettings}
                    user={user}
                    watch={watch}
                  />
                }
              />

              {/* ----- Step 7: Disclaimer -----*/}

              <Route
                path="disclaimer"
                element={
                  <Form className="mt-1">
                    <OnboardingDisclaimer
                      getValues={getValues}
                      handleSubmit={handleSubmit}
                      register={register}
                      riderType={riderType}
                      setValue={setValue}
                      submitSettings={submitSettings}
                      user={user}
                      watch={watch}
                    />
                  </Form>
                }
              />

              {/* ----- Step 8: Diversity -----*/}
              <Route
                path="diversity"
                element={
                  <Form className="mt-1">
                    <OnboardingDiversity
                      handleChange={handleChange}
                      handleSubmit={handleSubmit}
                      register={register}
                      riderType={riderType}
                      setValue={setValue}
                      state={state}
                      submitSettings={submitSettings}
                      user={user}
                    />
                  </Form>
                }
              />
            </Routes>
            <small className="text-muted">
              If you have any questions or concerns please{" "}
              <a
                href="mailto:cyclebuddies@lcc.org.uk"
                className="text-muted text-decoration-underline"
              >
                contact our Cycle Buddies team
              </a>
              .
            </small>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Onboarding;

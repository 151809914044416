import {useContext, useEffect, useState} from "react";
import {useParams, Link} from "react-router-dom";
import AuthContext from "../context/AuthContext";
import AuthenticationScreen from "../components/furniture/AuthenticationScreen";

const ActivatePage = () => {
  const {activateUser} = useContext(AuthContext);
  const {uid, token} = useParams();
  const [status, setStatus] = useState(null);

  // on load try to activate the user from the URI params otherwise show error
  useEffect(() => {
    activateUser(uid, token)
      .then(() => {
        setStatus('success');
      })
      .catch((error) => {
        setStatus('error');
      })
  }, []);

  return (
    <AuthenticationScreen title="Account activation">


      {status === 'success' && (
        <>
          <p>Your account has been activated</p>

          <p>
            You can <Link to="/login">Login</Link> to your account.
          </p>
        </>
      )}

      {status === 'error' && (
        <>
          <p>Problem during account activation. Please try again or contact service support for further help.
          </p>

          <p><Link to="/resend-activation" className="btn btn-primary rounded-pill ms-sm-auto px-3 mt-1">Resend activation email</Link></p>
        </>
      )}

    </AuthenticationScreen>
  )
}

export default ActivatePage;
import "./App.css";
import "./Chat.css";
import "./Config";
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { store, history } from "./Store";
import axios from "axios";
import { AuthProvider } from "./context/AuthContext";
import { ChatProvider } from "./context/ChatContext";
import PrivateRoute from "./utils/PrivateRoute";
import ProfileRoute from "./utils/ProfileRoute";
import "./theme/assets/scss/socialv.scss";
import "./theme/assets/scss/customizer.scss";
import "react-toastify/dist/ReactToastify.css";
import ScrollToTop from "./hooks/scrollToTop";

// pages
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import SignupPage from "./pages/SignupPage";
import ActivatePage from "./pages/ActivatePage";
import ResendActivationPage from "./pages/ResendActivationPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import ResetPasswordConfirmPage from "./pages/ResetPasswordConfirmPage";
import ProfilePage from "./pages/ProfilePage";
import PublicProfilePage from "./pages/PublicProfilePage";
import SettingsPage from "./pages/SettingsPage";

import FindBuddies from "./pages/FindBuddies";
import Chat from "./pages/Chat";
import Onboarding from "./pages/Onboarding";
import Welcome from "./pages/Welcome";

import { ToastContainer } from "react-toastify";
import { getReactAppApiBaseUrl } from "./utils/Utils";

function App() {
  axios.defaults.headers.common["Accept"] = "application/json";
  axios.defaults.baseURL = getReactAppApiBaseUrl();
  return (
    <Provider store={store}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        theme="light"
      />
      <BrowserRouter location={history.location} navigator={history}>
        <ScrollToTop />
        <AuthProvider>
          <ChatProvider>
            <Routes>
              <Route exact path="/" element={<HomePage />} />
              <Route path="/signup" element={<SignupPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/activate/:uid/:token" element={<ActivatePage />} />
              <Route
                path="/resend-activation"
                element={<ResendActivationPage />}
              />
              <Route path="/password-reset/" element={<ResetPasswordPage />} />
              <Route
                path="/password-reset/confirm/:uid/:token"
                element={<ResetPasswordConfirmPage />}
              />

              <Route
                exact
                path="/find-buddies/:uid"
                element={<ProfileRoute />}
              />
              <Route
                path="/settings"
                element={
                  <PrivateRoute>
                    <SettingsPage />
                  </PrivateRoute>
                }
              />

              <Route
                path="/find-buddies"
                element={
                  <PrivateRoute>
                    <FindBuddies />
                  </PrivateRoute>
                }
              />

              <Route
                path="/welcome"
                element={
                  <PrivateRoute>
                    <Welcome />
                  </PrivateRoute>
                }
              />

              <Route
                path="/chat"
                element={
                  <PrivateRoute>
                    <Chat />
                  </PrivateRoute>
                }
              />

              <Route
                path="/onboarding/*"
                element={
                  <PrivateRoute>
                    <Onboarding />
                  </PrivateRoute>
                }
              />
            </Routes>
          </ChatProvider>
        </AuthProvider>
      </BrowserRouter>
    </Provider>
  );
}

export default App;

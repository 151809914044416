import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Badge,
  Card,
  Col,
  Container,
  Row,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { store } from "../Store";
import {
  amityCreateNewChat,
  ChannelRepository,
} from "@amityco/ui-kit-open-source";

import profileImageDefaultMed from "../assets/images/lcc-user-profile-default-360.png";
import profileImageDefaultLrg from "../assets/images/lcc-user-profile-default-720.png";
import AuthContext from "../context/AuthContext";
import { getReactAppLccEmail } from "../utils/Utils";
import useStartOrContinueChat from "../hooks/useStartOrContinueChat";

const ProfilePage = () => {
  const navigate = useNavigate();
  const urlParams = useParams();
  const { dispatch } = store;
  const { user } = useContext(AuthContext);

  const buddy = useSelector(({ buddies }) => {
    return buddies?.profiles.find(({ uid }) => uid === urlParams?.uid);
  });

  const { alreadyChatting, startChat, continueChat } = useStartOrContinueChat(
    buddy?.uid,
    user?.uid
  );

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
    if (!buddy) {
      dispatch.buddies.getProfileById(urlParams?.uid);
    }
  }, [buddy]);

  if (!!buddy) {
    return (
      <Container>
        <Card>
          <div className="d-block d-md-none">
            <img
              src={buddy.profile_photo_med}
              srcSet={[
                `${buddy.profile_photo_med} 1x`,
                `${buddy.profile_photo_med} 2x`,
              ]}
              className="img-fluid rounded-top w-100"
              loading="lazy"
              alt=""
            />
          </div>
          <Card.Header className="px-3 py-3 px-md-5 py-md-4 g-2">
            <Row className="align-items-center g-3">
              <Col>
                <h1 className="h1">{buddy.username}</h1>
              </Col>
              {user?.rider_type === "NEW_RETURNING" && (
                <Col md="auto">
                  {alreadyChatting ? (
                    <Button
                      variant="primary"
                      size="lg"
                      className="w-100 w-sm-auto d-flex align-items-center gap-2"
                      onClick={continueChat}
                    >
                      <span className="material-symbols-sharp fs-4">chat</span>{" "}
                      Continue conversation
                    </Button>
                  ) : (
                    <Button
                      variant="primary"
                      size="lg"
                      className="w-100 w-sm-auto d-flex align-items-center gap-2"
                      onClick={startChat}
                    >
                      <span className="material-symbols-sharp fs-4">chat</span>{" "}
                      Start conversation
                    </Button>
                  )}
                </Col>
              )}
            </Row>
            <Row className="my-3">
              <div className="d-flex flex-row flex-wrap gap-3 mt-1 align-items-start align-items-sm-center">
                {buddy.rider_type === "INSTRUCTOR" && (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip>This user is a verified instructor</Tooltip>
                    }
                  >
                    <Badge
                      bg="dark"
                      text="white"
                      className="px-2 fw-bold d-flex align-items-center gap-1"
                    >
                      <span className="material-symbols-sharp fs-6">
                        verified
                      </span>{" "}
                      Instructor
                    </Badge>
                  </OverlayTrigger>
                )}

                {buddy.verified && (
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>This user has been DBS checked</Tooltip>}
                  >
                    <Badge
                      bg="dark"
                      text="white"
                      className="px-2 fw-bold d-flex align-items-center gap-1"
                    >
                      <span className="material-symbols-sharp fs-6">
                        verified_user
                      </span>{" "}
                      DBS Checked
                    </Badge>
                  </OverlayTrigger>
                )}
              </div>
            </Row>
          </Card.Header>
          <Card.Body className="px-3 py-3 px-md-5 py-md-4">
            <Row className="">
              <Col sm={12} lg={6}>
                {!!buddy?.introduction && <p>{buddy?.introduction}</p>}
                {!!buddy?.badges.length && (
                  <div className="my-4 align-items-start align-items-sm-center border-bottom my-4">
                    {buddy.badges.map(function (badge, index) {
                      return (
                        <div className="border-top py-2">
                          <OverlayTrigger
                            key={index}
                            placement="top"
                            overlay={
                              badge.description ? (
                                <Tooltip>{badge.description}</Tooltip>
                              ) : (
                                <></>
                              )
                            }
                          >
                            <div className="d-inline-flex align-items-center gap-2">
                              <span className="material-symbols-sharp fs-4">
                                {badge.icon}
                              </span>{" "}
                              {badge.name}
                            </div>
                          </OverlayTrigger>
                        </div>
                      );
                    })}
                  </div>
                )}
              </Col>
              <Col
                sm={12}
                lg={{ span: 5, offset: 1 }}
                className="d-none d-md-block"
              >
                <div className="">
                  <img
                    src={buddy.profile_photo_med || profileImageDefaultMed}
                    srcSet={[
                      `${buddy.profile_photo_med || profileImageDefaultMed} 1x`,
                      `${buddy.profile_photo_lrg || profileImageDefaultLrg} 2x`,
                    ]}
                    className="img-fluid rounded w-100"
                    loading="lazy"
                    alt=""
                  />
                </div>
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer className="px-3 py-2 px-md-5 py-md-4 border-top small">
            <a
              href={`mailto:${getReactAppLccEmail()}?subject=Report ${
                buddy?.username
              }`}
              className="d-flex gap-1 align-items-center"
            >
              <i className="material-symbols-sharp fs-6">flag</i> Report{" "}
              {buddy?.username}
            </a>
          </Card.Footer>
        </Card>
      </Container>
    );
  } else {
    return <></>;
  }
};

export default ProfilePage;

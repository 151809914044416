import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import OnboardingForm from "./OnboardingForm";
import {getExternalFAQUrl} from "../../utils/Utils";

const OnboardingDisclaimer = ({
  getValues,
  handleSubmit,
  register,
  riderType,
  setValue,
  submitSettings,
  user,
  watch,
}) => {
  let navigate = useNavigate();

  const watchFields = watch([
    "termsBeginner",
    "termsLevel3",
    "termsInstructor",
  ]);

  const [buttonEnabled, setButtonEnabled] = useState(false);

  const disclaimersPassOk = () => {
    return (
      (riderType === "NEW_RETURNING" && getValues("termsBeginner")) ||
      (riderType === "EXPERIENCED" && getValues("termsLevel3")) ||
      (riderType === "INSTRUCTOR" && getValues("termsInstructor"))
    );
  };

  useEffect(() => {
    setButtonEnabled(disclaimersPassOk());
  }, [watchFields]);

  return (
    <OnboardingForm
      page={7}
      title="Disclaimer"
      previousButton={{
        label: "Previous",
        onClick: () => navigate("/onboarding/address"),
      }}
      nextButton={{
        buttonEnabled: buttonEnabled,
        label: "Save profile",
        onClick: () => {
          if (disclaimersPassOk()) {
            let handleErrors = (e) => {
              console.error(e);
            };
            handleSubmit(submitSettings, handleErrors)();
            navigate("/onboarding/diversity");
          }
        },
      }}
    >
      {/* NEW OR RETURNING */}

      {riderType === "NEW_RETURNING" && (
        <div className="col-12">
          <Form.Group className="form-group pt-3">
            <Form.Check className="d-flex align-items-baseline py-0">
              <Form.Check.Input
                {...register("termsBeginner")}
                className="flex-shrink-0"
                type="checkbox"
                id="termsBeginner"
                required
              />
              <Form.Check.Label
                className="d-inline-block my-2 ps-2"
                htmlFor="termsBeginner"
              >
                <p>I confirm the following:</p>
                <ul>
                  <li>
                    I have read the{" "}
                    <a
                      href={`${getExternalFAQUrl()}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Cycle Buddy FAQs{" "}
                      <span className="material-symbols-outlined align-middle small">
                        open_in_new
                      </span>
                    </a>
                  </li>
                  <li>I confirm that I am over 18</li>
                  <li>
                    I understand that there are risks and I take part at my own
                    risk
                  </li>
                  <li>
                    I understand London Cycling Campaign may hold my information
                    as outlined in the{" "}
                    <a
                      href={`${getExternalFAQUrl()}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      FAQs{" "}
                      <span className="material-symbols-outlined align-middle small">
                        open_in_new
                      </span>
                    </a>
                  </li>
                  <li>
                    I agree to London Cycling Campaign contacting me regarding
                    membership, campaigns and related topics
                  </li>
                  <li>
                    I understand that this is an introductory platform only and
                    that London Cycling Campaign is not responsible for any
                    action, injury or cost that arises or follows from that
                    introduction
                  </li>
                  <li>
                    I have read the advice on personal safety and meeting up
                    with cycle buddies in the{" "}
                    <a
                      href={`${getExternalFAQUrl()}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      FAQs{" "}
                      <span className="material-symbols-outlined align-middle small">
                        open_in_new
                      </span>
                    </a>
                  </li>
                </ul>
              </Form.Check.Label>
            </Form.Check>
          </Form.Group>
        </div>
      )}

      {/* EXPERIENCED */}
      {riderType === "EXPERIENCED" && (
        <div className="col-12">
          <Form.Group className="form-group pt-3">
            <Form.Check className="d-flex align-items-baseline py-0">
              <Form.Check.Input
                {...register("termsLevel3")}
                id="termsLevel3"
                className="flex-shrink-0"
                type="checkbox"
                required
              />
              <Form.Check.Label
                className="d-inline-block my-2 ps-2"
                htmlFor="termsLevel3"
              >
                <p>I confirm the following:</p>
                <ul>
                  <li>
                    I confirm that I can cycle at{" "}
                    <a
                      href={`${getExternalFAQUrl()}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      BikeriderType Level 3{" "}
                      <span className="material-symbols-outlined align-middle small">
                        open_in_new
                      </span>
                    </a>{" "}
                    or equivalent experience
                  </li>
                  <li>
                    I have read the{" "}
                    <a
                      href={`${getExternalFAQUrl()}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Helper Buddy FAQs{" "}
                      <span className="material-symbols-outlined align-middle small">
                        open_in_new
                      </span>
                    </a>
                  </li>
                  <li>I confirm that I am over 18</li>
                  <li>
                    I understand that there are risks and I take part at my own
                    risk and I understand that the people I help might not
                    understand those risks and I have a duty of care to them
                  </li>
                  <li>
                    I understand London Cycling Campaign may hold my information
                    as outlined in the{" "}
                    <a
                      href={`${getExternalFAQUrl()}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      FAQs{" "}
                      <span className="material-symbols-outlined align-middle small">
                        open_in_new
                      </span>
                    </a>
                  </li>
                  <li>
                    I agree to LCC contacting me regarding membership, campaigns
                    and related topics
                  </li>
                  <li>
                    I understand that this is an introductory platform only and
                    that London Cycling Campaign is not responsible for any
                    action, injury or cost that arises or follows from any
                    introduction
                  </li>
                </ul>
              </Form.Check.Label>
            </Form.Check>
          </Form.Group>
        </div>
      )}

      {/* INSTRUCTOR */}
      {riderType === "INSTRUCTOR" && (
        <div className="col-12">
          <Form.Group className="form-group pt-3">
            <Form.Check className="d-flex align-items-baseline py-0">
              <Form.Check.Input
                {...register("termsInstructor")}
                className="flex-shrink-0"
                type="checkbox"
                id="termsInstructor"
                required
              />
              <Form.Check.Label
                className="d-inline-block my-2 ps-2"
                htmlFor="termsInstructor"
              >
                <p>I confirm the following:</p>
                <ul>
                  <li>
                    I have read the{" "}
                    <a
                      href={`${getExternalFAQUrl()}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Cycle Instructor FAQs{" "}
                      <span className="material-symbols-outlined align-middle small">
                        open_in_new
                      </span>
                    </a>
                  </li>
                  <li>
                    I confirm that I am a registered NSI or 1st4Sport Cycling
                    Instructor
                  </li>
                  <li>
                    I confirm I have Cycle Activity Provider cover from Cycling
                    UK, British Cycling, Butterworth Spengler or another insurer
                  </li>
                  <li>
                    I understand that there are risks and I take part at my own
                    risk and I understand that the people I help might not
                    understand those risks and I have a duty of care to them
                  </li>
                  <li>
                    I have read and understood the Cycle Buddie Helper Pack
                  </li>
                  <li>
                    I understand London Cycling Campaign may hold my information
                    as outlined in the{" "}
                    <a
                      href={`${getExternalFAQUrl()}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      FAQs{" "}
                      <span className="material-symbols-outlined align-middle small">
                        open_in_new
                      </span>{" "}
                    </a>
                  </li>
                  <li>
                    I agree to London Cycling Campaign contacting me regarding
                    membership, campaigns and related topics
                  </li>
                  <li>
                    I understand that this is an introductory platform only and
                    that London Cycling Campaign is not responsible for any
                    action, injury or cost that arises or follows from any
                    introduction
                  </li>
                </ul>
              </Form.Check.Label>
            </Form.Check>
          </Form.Group>
        </div>
      )}
    </OnboardingForm>
  );
};

OnboardingDisclaimer.defaultProps = {};

OnboardingDisclaimer.propTypes = {
  riderType: PropTypes.object,
};

export default OnboardingDisclaimer;

const CHAT_INTENT_BUDDY_UID_KEY = "CHAT_INTENT_BUDDY_UID";
const CHAT_INTENT_TIMESTAMP_KEY = "CHAT_INTENT_TIMESTAMP";

export const setChatIntent = (buddyUID, timeStamp) => {
  window.localStorage.setItem(CHAT_INTENT_BUDDY_UID_KEY, buddyUID);
  window.localStorage.setItem(CHAT_INTENT_TIMESTAMP_KEY, timeStamp);
};

export const getChatIntent = () => {
  const encodedBuddyUID = window.localStorage.getItem(
    CHAT_INTENT_BUDDY_UID_KEY
  );
  const buddyUID = encodedBuddyUID
    ? atob(window.localStorage.getItem(CHAT_INTENT_BUDDY_UID_KEY))
    : undefined;
  const chatIntentTimestamp = window.localStorage.getItem(
    CHAT_INTENT_TIMESTAMP_KEY
  );

  return { buddyUID, chatIntentTimestamp };
};

export const removeChatIntent = () => {
  window.localStorage.removeItem(CHAT_INTENT_BUDDY_UID_KEY);
  window.localStorage.removeItem(CHAT_INTENT_TIMESTAMP_KEY);
};

import { useState, useEffect } from "react";
import {
  amityCreateNewChat,
  ChannelRepository,
} from "@amityco/ui-kit-open-source";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const useStartOrContinueChat = (buddyUID, userUID) => {
  const navigate = useNavigate();

  // TODO: need to check if the user has an existing chat with this user so we send them to that or create a new one

  const [alreadyChatting, setAlreadyChatting] = useState(false);
  const [potentialChannelId, setPotentialChannelId] = useState("");

  useEffect(() => {
    if (!!buddyUID && !!userUID) {
      const _potentialChannelId = userUID + "-" + buddyUID;
      setPotentialChannelId(_potentialChannelId);
    }
  }, [buddyUID, userUID]);

  useEffect(() => {
    if (!!potentialChannelId) {
      const liveChannel = ChannelRepository.getChannel(potentialChannelId);
      if (
        liveChannel?.dataStatus === "fresh" ||
        liveChannel?.dataStatus === "local"
      ) {
        setAlreadyChatting(true);
      } else {
        setAlreadyChatting(false);
      }
    }
  }, [potentialChannelId]);

  const startChat = () => {
    amityCreateNewChat({
      isDirectChat: true,
      userIds: [buddyUID],
      currentUserId: userUID,
      onChannelIdCreated: (channelId) => {
        axios.post("/api/v1/chats/", {
          channel_id: channelId,
          user_uid: buddyUID,
        });
      },
      onChatCreated: (chat) => {
        navigate(`/chat?channelId=${chat.channelId}`);
      },
    });
  };

  const continueChat = () => {
    navigate(`/chat?channelId=${potentialChannelId}`);
  };

  return { alreadyChatting, startChat, continueChat };
};

export default useStartOrContinueChat;

import React, { useEffect, useRef, useContext, useState } from "react";
import AuthContext from "../context/AuthContext";
import BuddyCard from "../components/buddies/BuddyCard";
import { useSelector } from "react-redux";
import { store } from "../Store";
import { Link, useNavigate } from "react-router-dom";
import { Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import profileImageDefaultMed from "../assets/images/lcc-user-profile-default-360.png";
import profileImageDefaultLrg from "../assets/images/lcc-user-profile-default-720.png";
import useStartOrContinueChat from "../hooks/useStartOrContinueChat";
import { getChatIntent, removeChatIntent } from "../utils/chatIntent";

const FindBuddies = () => {
  const { dispatch } = store;
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { profiles } = useSelector(({ buddies }) => {
    const profileIds = [];
    const dedupedProfiles = [];
    buddies.profiles.forEach((profile) => {
      if (!profileIds.includes(profile.uid)) {
        profileIds.push(profile.uid);
        dedupedProfiles.push(profile);
      }
    });
    return {
      isLoading: buddies.isLoading,
      profiles: dedupedProfiles,
    };
  });

  const [riderType, setRiderType] = useState(null);
  const [area, setArea] = useState(
    user.home_postcode ? "home" : user.work_postcode ? "work" : null
  );
  const [distance, setDistance] = useState("48280");
  const [matchGender, setMatchGender] = useState(false);

  const riderTypeSelectRef = useRef();
  const areaSelectRef = useRef();
  const distanceSelectRef = useRef();
  const matchGenderCheckboxRef = useRef();

  const getSelectedOption = (select) => {
    if (select.selectedOptions || select.selectedOptions.length > 0) {
      let value = select.selectedOptions[0].value;
      if (value !== "-1") {
        return value;
      }
    }

    return null;
  };

  const updateRiderType = () => {
    setRiderType(getSelectedOption(riderTypeSelectRef.current));
  };

  const updateArea = () => {
    setArea(getSelectedOption(areaSelectRef.current));
  };

  const updateDistance = () => {
    setDistance(getSelectedOption(distanceSelectRef.current));
  };

  const updateMatchGender = () => {
    setMatchGender(matchGenderCheckboxRef.current.checked);
  };

  useEffect(() => {
    let params = [];

    params.push(`area=${area}`);
    params.push(`matchGender=${matchGender}`);

    if (riderType) {
      params.push(`riderType=${riderType}`);
    }

    if (distance) {
      params.push(`distance=${distance}`);
    }

    dispatch.buddies.loadData(params);
  }, [riderType, area, distance, matchGender]);

  const { buddyUID, chatIntentTimestamp } = getChatIntent();
  const startOrContinueChat = useStartOrContinueChat(buddyUID, user?.uid);

  useEffect(() => {
    // if user tries to get to this page and they are not onboarded redirect them to the onboarding
    if (!user?.onboarded) {
      navigate("/onboarding");
      return;
    }

    // If they're not a beginner redirect them to the welcome page
    if (user?.rider_type !== "NEW_RETURNING") {
      navigate("/welcome");
      return;
    }

    // If they have a buddyUID stored in their chat intent local storage
    // And the page is connected - this is required so make sure amity is loaded
    if (!!buddyUID) {
      // If the start or continue chat methods don't exist then return early.
      if (
        !startOrContinueChat?.continueChat ||
        !startOrContinueChat?.startChat
      ) {
        return;
      }

      const isTimeoutNotExceeded =
        Date.now() - chatIntentTimestamp <
        parseInt(window.Config.REACT_APP_LINK_TIMEOUT_MINUTES) * 60000;

      // If the timeout value isn't exceeded
      if (isTimeoutNotExceeded) {
        // Start or continue the chat with the buddy
        if (startOrContinueChat?.alreadyChatting) {
          startOrContinueChat?.continueChat();
        } else {
          startOrContinueChat?.startChat();
        }
      }

      // Remove the chat intent params from local storage
      removeChatIntent();
    }
  }, [startOrContinueChat]);

  useEffect(() => {
    // if user tries to get to this page and they are not onboarded or not a beginner forward them onto correct page
    if (!user?.onboarded) {
      navigate("/onboarding");
    } else {
      if (user?.rider_type !== "NEW_RETURNING") {
        navigate("/welcome");
      }
    }
  });

  const filters = (
    <Form>
      <Row className="mb-3 g-3">
        {user.goals.includes("PARTICULAR_ROUTE") && (
          <Col sm="auto">
            <InputGroup className="flex-grow-0 w-auto flex-shrink-0">
              <InputGroup.Text>With</InputGroup.Text>

              <Form.Select
                ref={riderTypeSelectRef}
                defaultValue={riderType}
                onChange={updateRiderType}
              >
                <option value="-1">All riders</option>
                <option value="EXPERIENCED">Experienced rider</option>
                <option value="INSTRUCTOR">Qualified instructor</option>
              </Form.Select>
            </InputGroup>
          </Col>
        )}

        {user.home_postcode && user.work_postcode && (
          <Col sm="auto">
            <InputGroup className="flex-grow-0 w-auto flex-shrink-0">
              <InputGroup.Text>Near</InputGroup.Text>

              <Form.Select
                aria-label="Location:"
                ref={areaSelectRef}
                defaultValue={area}
                onChange={updateArea}
              >
                <option value="home">Home</option>
                <option value="work">Work</option>
              </Form.Select>
            </InputGroup>
          </Col>
        )}

        <Col sm="auto">
          <InputGroup className="flex-grow-0 w-auto flex-shrink-0">
            <InputGroup.Text>Within</InputGroup.Text>

            <Form.Select
              ref={distanceSelectRef}
              defaultValue={distance}
              onChange={updateDistance}
            >
              <option value="4828">3 miles</option>
              <option value="8047">5 miles</option>
              <option value="12875">8 miles</option>
              <option value="17703">11 miles</option>
              <option value="48280">11+ miles</option> {/* up to 30 miles */}
            </Form.Select>
          </InputGroup>
        </Col>

        {user.gender &&
          (user.gender === "MALE" || user.gender === "FEMALE") && (
            <Col sm="auto" className="ms-auto">
              <Form.Check
                type="switch"
                ref={matchGenderCheckboxRef}
                defaultChecked={matchGender}
                onClick={updateMatchGender}
                id="custom-switch"
                label="Match gender"
              />
            </Col>
          )}
      </Row>
    </Form>
  );

  return (
    <>
      <Container fluid className="visually-hidden">
        <Row className="mb-3">
          <Col sm="12" lg="12">
            <h1>Find Buddies</h1>
          </Col>
        </Row>
      </Container>

      {user?.rider_type === "NEW_RETURNING" && (
        <>
          <Container fluid>
            <Row className="mb-1">{filters}</Row>
          </Container>

          <Container fluid>
            {profiles.length ? (
              <>
                <Row
                  xs={2}
                  md={3}
                  lg={4}
                  xl={5}
                  xxl={6}
                  className="g-3 align-items-stretch"
                >
                  {profiles.map(
                    (
                      {
                        uid,
                        profile_photo_med,
                        profile_photo_lrg,
                        username,
                        rider_type,
                        location,
                        distance_from,
                        badges,
                        verified,
                      },
                      key
                    ) => {
                      return (
                        <Col key={`buddy-card-id-${key}`}>
                          <BuddyCard
                            uid={uid}
                            imgSrc={profile_photo_med || profileImageDefaultMed}
                            srcSet={[
                              profile_photo_med || profileImageDefaultMed,
                              profile_photo_lrg || profileImageDefaultLrg,
                            ]}
                            locationString={location}
                            name={username}
                            type={rider_type}
                            distanceFrom={distance_from}
                            profileBadges={badges}
                            verified={verified}
                          />
                        </Col>
                      );
                    }
                  )}
                </Row>

                {/* Load more results */}
                {/* <div className="my-5 gap-2 d-flex flex-column flex-sm-row justify-content-center">
                <Button
                  variant="primary"
                  size="lg"
                  className="rounded-pill px-3"
                  disabled={isLoading}
                  onClick={!isLoading ? handleClick : null}
                >
                  {isLoading ? "Loading…" : "View more"}
                </Button>
              </div> */}
              </>
            ) : (
              <>
                <div className="d-flex flex-column align-items-center justify-content-center py-5">
                  <h2>Sorry, no Cycle Buddies found near your location</h2>
                  <p>
                    <Link to="/settings">Change your location.</Link>
                  </p>
                </div>
              </>
            )}
          </Container>
        </>
      )}
    </>
  );
};

export default FindBuddies;

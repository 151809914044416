import React from "react";
import { Container, Image, Row, Col } from "react-bootstrap";
import logo from "../../assets/images/lcc-logo-white.svg";

const Footer = () => {
  return (
    <>
      <footer className="py-3 px-2 bg-dark small text-white">
        <Container fluid>
          <Row className="py-3">
            <Col lg={4}>
            <a href="https://lcc.org.uk/">
              <Image
                src={logo}
                className="img-fluid"
                alt="logo"
                style={{ maxWidth: "200px" }}
              /></a>
              </Col>
            <Col lg={8}></Col>
          </Row>
          <Row>
            <Col lg="auto">
              <ul className="list-inline mb-0">
                <li className="list-inline-item">
                  <a href="https://lcc.org.uk/privacy-policy/" className="text-light" target="_blank">Privacy Policy</a>
                </li>{" "}
                <li className="list-inline-item">
                  <a href="https://lcc.org.uk/terms-conditions/" className="text-light" target="_blank">Terms of Use</a>
                </li>
                <li className="list-inline-item">
                  <a href="https://spork.digital" className="text-white-50" target="_blank">Built by Spork</a>
                </li>
              </ul>
            </Col>
            <Col className="d-flex justify-content-md-end">
              <span>
                Copyright{" "}<a href="https://lcc.org.uk/" className="text-light text-decoration"> London Cycling Campaign</a>. Registered Charity No: 1115789
              </span>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
